import React from 'react';
import Lottie from 'react-lottie-player';
import lottieJson from 'assets/images/loader.json';
import './loader.scss';

const Loader = ({ height = 100, width = 120}) => {
	return (
		<div className="loader">
			<Lottie loop animationData={lottieJson} play style={{ width: width, height: height }} />
		</div>
	);
};

export default Loader;