import React, {useEffect} from 'react'
import { useSearchParams } from "react-router-dom";
import { getThemeData } from 'utils/storage';
import { saveToken, savePartnerId, saveUserId } from 'view-models/authViewModel';

const CustomTheme = () => {
    const [searchParams] = useSearchParams();
    const token = searchParams.get("accessToken")? searchParams.get("accessToken") : null;
    const partnerId = searchParams.get("partnerId")? searchParams.get("partnerId") : null;
    const userId = searchParams.get("userId")? searchParams.get("userId") : null;

    useEffect(() => {
        saveToken(token);
        savePartnerId(partnerId);
        saveUserId(userId);
    }, [partnerId, token, userId])
    
    const {color} = getThemeData();
    const filterColor = localStorage.getItem('svgColor');
    const dynamicCSS = `
        .buttonContainer .row label :checked ~ .icon-box{
            border: 1px solid ${color? color : "--var(--primary)!important"};
            color: ${color? color : "--var(--primary)!important"};
            background-color: ${color? color : "--var(--primary)"}14!important;
        }
        .progress {
            background: ${color? color : "--var(--primary)"}!important;
        }

        .styledBtn{
            box-shadow: 0px 3px 12px ${color? color : "--var(--primary)"}14!important;
        }
            
        progress::-webkit-progress-value {
            background-color: ${color? color : "--var(--primary)"}!important;
            border-radius: 64px;
        }

        .buttonContainer .boolean label :checked ~ .confirmBtn {
            background-color: ${color+`14`? color+`14` : "#4A3AFF14"}!important;
        }

        div#suggestionBox div:hover {
            background: ${color+`14`? color+`14` : "#4A3AFF14"}!important;
        }
        
        .discountList .discountItems :checked ~ span {
            background-color: ${color+`14`? color+`14` : "#4A3AFF14"}!important;
        }

        
        .buttonContainer label :checked ~ .tiles {
            background-color: ${color+`14`? color+`14` : "#4A3AFF14"}!important;
            font-weight: bold;
        }

        :root {
            --primary: ${color? color : "#4A3AFF"};
        }

        .confirmBtn img{
            ${filterColor ? filterColor : "filter: invert(16%) sepia(5%) saturate(33%) hue-rotate(316deg) brightness(92%) contrast(86%)!important"};
        }

        .whyPopup img{
            ${filterColor ? filterColor : "filter: invert(16%) sepia(5%) saturate(33%) hue-rotate(316deg) brightness(92%) contrast(86%)!important"};
        }

        .form-control:focus {
            border-color: ${color? color : "--var(--primary)"}14!important;
            box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px ${color? color : "--var(--primary)"};
        }

        .toggle-btn{
            background: ${color+`14`? color+`14` : "#4A3AFF14"};
        }

        footer{
            background: ${color+`14`? color+`14` : "#4A3AFF14"};
        }
    `;

    return (
        <style>{dynamicCSS}</style>
    )
}

export default CustomTheme