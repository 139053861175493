import React, {useState, useEffect, useRef} from 'react'
import './confirm.scss'
import { useFormik } from 'formik';
import * as submission from '../../../view-models/discountSubmissionViewModel'
import { useNavigate } from "react-router-dom";
import { Icon } from '@iconify/react';
import { getThemeData } from 'utils/storage';
import { logMessage } from 'utils/errorHandler';
import { TrackGoogleAnalyticsCustomEvent, TrackGoogleAnalyticsEvent } from 'utils/google-analytics';
import ReactGA from "react-ga";
import { isEmpty } from 'utils/tools';

const Confirmation = () => {
  const currentEstimatedDiscount = submission.getEstimatedDiscount();
  let navigate = useNavigate();
  const [discountAmount, setDiscount] = useState('');
  const [PolicyAmount, setPolicyAmount] = useState(isEmpty(localStorage.policyAmount)? isEmpty(localStorage.policyAmount): '2000');
  const [isClearable, setIsClearable] = useState(true);
  const [apiErrors, setApiErrors] = useState();
  const [errors, setErrors] = useState();
  const [dateType, setDateType] = useState('year');
  const [monthPrice, setMonthPrice] = useState(discountAmount.estimatedSavingsPerYear ? discountAmount.estimatedSavingsPerYear : 0);
  const effectRan = useRef(false);

  const insurerId = submission.getInsuranceId();

  const primaryColor = getThemeData() ? getThemeData().color : '#4a3aff' ;


  const handleSubmit = async (e) => {
    e.preventDefault();
    try{
      if (isClearable){
        setErrors("")
        formik.values = Object.assign({"canProvidePhotoCertificate": true, "policyAmount": PolicyAmount}, formik.values)
        
        if (!discountAmount.estimatedDiscount){
          const discount = {
            "insurerId": insurerId,
            ...(PolicyAmount) && {policyAmount: PolicyAmount}
          };
         
          const amount = await submission.getDiscountAmount(discount)
          setPolicyAmount(discount.policyAmount)
          setDiscount(amount.data)
        }
        await submission.updateUser(formik.values)
        submission.savePolicyAmount(PolicyAmount)
        submission.saveEstimatedDiscount(discountAmount.estimatedDiscount)
        TrackGoogleAnalyticsEvent("category:formSubmit","action: caculateDiscount")
        TrackGoogleAnalyticsCustomEvent("{canProvidePhotoCertificate: true}")
        navigate('/discounts')
      }else{
        setErrors( 'Please accept this statement to continue')
      }
    }catch(err){
      const message = logMessage(err)
      setApiErrors(message)
    }
  }

  /*const handleBlur = () => {
    const discount = {
      "insurerId": insurerId,
      ...(PolicyAmount) && {policyAmount: PolicyAmount}
    };
    console.log(discount)
    const amount = Math.round(discountAmount.estimatedDiscount * discount.policyAmount * discountAmount.factor) / 100 ;//await submission.getDiscountAmount(discount)
    setPolicyAmount(discount.policyAmount)
    setDiscount({estimatedSavingsPerYear: amount, estimatedDiscount: discountAmount.estimatedDiscount});
    return true;
  }*/

  const dateSwitch = (e) => {
    const type = e.target.value;
    switch (type) {
      case 'yr':
        setDateType('year');
        setMonthPrice(discountAmount.estimatedSavingsPerYear)
        break;
      case 'mo':
        setDateType('month');
        setMonthPrice(submission.monthCaculator(discountAmount.estimatedSavingsPerYear))
        break;
      default:
        break;
    }

  }

  const handleKeyUp = (e) => {
    formik.handleChange(e)
    setDiscountAmount(insurerId, e.target.value);
  }

  const setDiscountAmount = async (insurerId, policyAmount) => {
     var finalAmount = 0
     if (discountAmount !== "") {
        finalAmount = Math.round(discountAmount.estimatedDiscount * policyAmount * discountAmount.factor) / 100 ; //await submission.getDiscountAmount(discount)
        setPolicyAmount(policyAmount)
        setDiscount({estimatedSavingsPerYear: finalAmount, estimatedDiscount: discountAmount.estimatedDiscount, 
        factor: discountAmount.factor});

     } else {
        const discount = {
          "insurerId": insurerId,
          "policyAmount": policyAmount
        };
        const amount = await submission.getDiscountAmount(discount);
        setPolicyAmount(discount.policyAmount);
        setDiscount(amount.data);
        finalAmount = amount.data.estimatedSavingsPerYear;
     }

     switch (dateType) {
      case 'year':
        setMonthPrice(finalAmount)
        return;
      case 'month':
        setMonthPrice(submission.monthCaculator(finalAmount))
        return;
      default:
        return;
    }
  }

  let formik = useFormik({
    initialValues: {
      policyAmount: PolicyAmount? PolicyAmount : '',
    },
    onSubmit: () => {
        handleSubmit();
    }
  });

  useEffect(() => {
    if (!effectRan.current) {
      setDiscountAmount(insurerId, PolicyAmount);

    }

    return () => effectRan.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section className='savings'>
      <div className='saving-container'>
          <div className='discount-ready'>Your Discount is Ready!</div>
          <div className='confirmInfo'>
              <div className='savingsText'>You can save up to</div>
              <div className='estimate'><span>{isEmpty(discountAmount.estimatedDiscount)? isEmpty(discountAmount.estimatedDiscount) : isEmpty(currentEstimatedDiscount)}%</span> /yr</div>
              <div className='bottomDisclaimer'>
                <div className='photoBtn'>
                    <label for="photo"> 
                      <p className='photoDisclaimer'>I certify accuracy, will provide proof if requested and authorize Dory to communicate with my insurer/agent for discount requests. Discount is subject to change. <ReactGA.OutboundLink eventLabel="developers" to="https://meetdory.com/legal"target="_blank">See Terms of Use.</ReactGA.OutboundLink></p>
                      <span className='checkbox'>
                          <input type="checkbox" id='photo' checked={isClearable} onChange={() => setIsClearable((state) => !state)} name="canProvidePhotoCertificate" value="true" />
                          <span className='checkmark'></span>
                      </span>
                    </label>
                </div>
                {(errors && !isClearable) && <span className='input-error'>{errors}</span>}
              </div>
              <form className='desktopBtn' onSubmit={handleSubmit}>
                {(apiErrors && !isClearable) && <span className='input-error'>{apiErrors}</span>}
                <button className='styledBtn primary-btn' type="submit">Claim My Discount</button>
              </form>
          </div>
      </div>
      <form className='mobileBtn' onSubmit={handleSubmit}>
        {(apiErrors && !isClearable) && <span className='input-error'>{apiErrors}</span>}
        <button className='styledBtn primary-btn' type="submit">Claim My Discount</button>
      </form>
      <div className='calculator'>
        <div className='calculator-container flex'>
          <div className='col-one'>
            <Icon icon="mdi:calculator" color={primaryColor} width="100" height="95"/>
            <div className='small-block'>
              <p className='saving-header'>Saving Calculator</p>
              <p className='saving-text'>Estimate how much you can save per {dateType}</p>
            </div>
          </div>
          <div className='policyRate'>
            <input type="number" name="policyAmount" className="form-control dollar" id="floatingInput" placeholder="Enter (approx.) Policy Rate" onChange={handleKeyUp} value={formik.values.policyAmount}/>
            <div className='toggle'>
              <input id="toggle-on" className="toggle toggle-left" name="toggle" onClick={dateSwitch} value="yr" type="radio" defaultChecked="true"/>
              <label for="toggle-on" className="toggle-btn">Yr</label>
              <input id="toggle-off" className="toggle toggle-right" name="toggle" onClick={dateSwitch} value="mo" type="radio" />
              <label for="toggle-off" className="toggle-btn">Mo</label>
            </div>
            {(monthPrice > 0 && monthPrice) && 
              <div className='amountSaved'>
                <p className='saver'>You could save up to <span>${monthPrice ? monthPrice : ""}</span> a {dateType}</p>
                <p className='savingDisclaimer'>Some insurance companies use factored, rather than flat discounts, your savings may not be a direct percentage of your policy rate.</p>
              </div> 
            }
          </div>
        </div>
      </div>
    </section>
  )
}

export default Confirmation