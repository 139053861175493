import './compareList.scss';
import React, {useState, useEffect} from 'react'
import DiscountContainer from '../Shared/DiscountContainer';
import * as compare from '../../view-models/compareDiscountsViewModel';
import pig from '../../assets/images/pig.svg';
import * as discounts from 'components/DiscountForm/SubmitDiscount';
import Loader from 'components/Shared/Loader';

const CompareDiscounts = () => {
  const [buttonClose, setCloseButton] = useState(false);
  let [offers, setOffers] = useState([])
  let offerDetails = compare.getOffersStorage()? compare.getOffersStorage() : offers;
  const [isLoading, setIsLoading] = useState(true);

  const [showComplete, setCompleteSection] = useState(compare.getAgentInfo()? true : false);

  if (typeof offerDetails.offers != "undefined" && offerDetails.offers != null && offerDetails.offers.length != null
  && offerDetails.offers.length > 0 ){
    offerDetails.offers[0].bkg = 'highlight';
  }

  const fetchOffers = async () => {
    try {
      const data = await compare.getOffers();
      setIsLoading(true)
      setOffers(data)
      setIsLoading(false)
    } catch (error) {
    }
  }

  useEffect(() => {
    fetchOffers()
  }, [])
  
  
  return (
    <div className='discountContainer'>
      {(!compare.getAgentInfo() && !buttonClose) && <discounts.CompleteDiscount button={buttonClose} setButton={setCloseButton}/> }
      {(buttonClose || showComplete) && 
        <div className='claimDiscount'>
          {showComplete ? <discounts.ClaimedDiscounts trigger={buttonClose} showClaimed={showComplete} setComplete={setCompleteSection} setTrigger={setCloseButton} /> : <discounts.Discounts formStatus="false" button={buttonClose} setButton={setCloseButton} trigger={showComplete} setTrigger={setCompleteSection}/> }
        </div>
      }
      <div className='topSection'>
        <img src={pig} alt='Pig Saving' />
        <p className='heading1'>Maximize your savings</p>
        <p className='heading2'>See the best deals for your discount profile</p>
      </div>
      {(typeof offerDetails.offers != "undefined" && offerDetails.offers != null && offerDetails.offers.length != null
  && offerDetails.offers.length > 0) ? (
        <>
          {offerDetails.offers && offerDetails.offers.map(info => (
            <DiscountContainer data={info} key={info.id} currentDiscount={offerDetails.currentInsurerDiscount}  />
          ))}
        </>
      ) : (
        <>
          {isLoading ? <Loader /> : 
          <div className='noOffers'>
            <p>There are no offers available for you at this time.</p>
          </div>
          }
          
        </>
      )}
    </div>
  )
}

export default CompareDiscounts