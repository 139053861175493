import React, {useEffect} from 'react';
import { Route, Routes } from 'react-router-dom';
import DiscountForm from '../../components/DiscountForm';
import './create.scss';
import List from '../../utils/constants/pageList.json';
import Confirmation from '../../components/DiscountForm/Confirm';
import AbodeConfirmation from '../../components/DiscountForm/ConfirmAbode';
import PageNotFound from '../../components/routing/error';
import { getProviders } from '../../api';
import Discounts from '../../components/DiscountForm/SubmitDiscount';
import CompareDiscounts from '../../components/CompareDiscounts';
import Legal from 'components/Switcher/Legal';
import Layout from 'components/Layout';
import NavBar from 'components/Shared/Navbar';
import Footer from 'components/Shared/Footer';
import CustomTheme from 'components/Shared/CustomTheme';

const Create = () => {
  useEffect(() => {
    getProviders()
  }, [])

  return (
    <Layout>
      <CustomTheme />
      <NavBar />
        <div className='formCenter'>
            <Routes>
                <Route path='/confirm' exact element={<Confirmation />} />
                <Route path='/abodeConfirm' exact element={<AbodeConfirmation />} />
                <Route path='/discounts' exact element={<Discounts formStatus="true"/>} />
                <Route path='/compare' exact element={<CompareDiscounts />} />
                <Route path='/legal' exact element={<Legal />} />
                {List.pageList.map(info => (
                  <Route path={info.path} key="" exact element={<DiscountForm data={info} />} />  
                  ))}
                <Route path="/*" element={<PageNotFound/>} />
            </Routes>
        </div>
      <Footer/>
    </Layout>
  )
}

export default Create