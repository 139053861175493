import { userInfo } from "../api";

export function getOffersStorage() {
    if (localStorage.offers){
        return JSON.parse(localStorage.offers) || "null";
    }
}

export function getAgentInfo(){
    return localStorage.agentInfo || null;
}

export function getClaimInfo(){
    return localStorage.claimFree || null;
}

function getUserId() {
    return localStorage.userId || null;
}



export const getOffers = async () =>{
    const url = `${process.env.REACT_APP_DORY_BASEURL}/user/v1/${getUserId()}/discounts/compare`;
    const method = "get";
    try{
        const offers = await userInfo(url, method)
        return offers.data ;
    }catch(err){
        console.log(err)
        return Promise.reject(err)
    }
}