import React, {useEffect} from 'react'
import { useSearchParams, useNavigate } from "react-router-dom";
import {checkUser} from '../../view-models/authViewModel';
import Loader from 'components/Shared/Loader';
import { getTheme } from 'api';
import { Redirect } from 'utils/tools';

const Login = () => {
  let navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get("accessToken")? searchParams.get("accessToken") : null;
  const partnerId = searchParams.get("partnerId")? searchParams.get("partnerId") : null;
  const userId = searchParams.get("userId")? searchParams.get("userId") : null;

  const fetchUser = async () => {
    if(token && partnerId){
      localStorage.clear();
      localStorage.setItem("authToken", token);
      localStorage.setItem("partnerId", partnerId);
      localStorage.setItem("userId", userId);
      await getTheme();
      if(userId !== "null"){
          await checkUser(userId)
      }

      navigate("/001");
    }else{
      Redirect()
    }
  }
  
  useEffect(() => {
    fetchUser();
  })
  
  return (
    <Loader />
  )
}

export default Login