import React from 'react';
import './popup.scss';
import { Logout } from 'utils/tools';
import { Icon } from '@iconify/react';

export function Popup(props) {
    return (props.trigger) ? (
        <div className="popup">
            <div className="popup-inner">
                <button className="close-btn" onClick={() => props.setTrigger(false)}>X</button>
                {props.children}
            </div>
        </div>
    ) : "";
}


export const SpeechBubble = (props) => {
    return (props.trigger) ? (
        <div className="bubble speech">
            {props.children}
            <button className="close-btn" onClick={() => props.setTrigger(false)}><Icon icon="material-symbols:close" /></button>
        </div>
    ) : "";
}

export const SignOut = (props) => {
    return (props.trigger) ? (
        <div  className='signout'>
            <div onClick={() => Logout()} className='box'>Sign Out</div>
        </div>
    ) : "";
}


export default Popup;
