import axios from 'axios';
import { json } from 'react-router-dom';
import { getPartnerId, getToken } from 'view-models/authViewModel';
import { saveInsurerList, saveTheme } from '../utils/storage';
import { logMessage } from 'utils/errorHandler';
import { filterGen } from 'utils/filterGen';

export const getProviders = async function() {
	const base = `${process.env.REACT_APP_DORY_BASEURL}/insurance/v1/providers`;
	const config = {
		url: base,
		method: 'get',
		headers: {
			'Authorization': getToken(),
			'Content-Type': 'application/json'
		}
	}
    try {
        const list = await axios(config);
        saveInsurerList(list.data);
    } catch(error){
        const message = logMessage(error)
        return Promise.reject(message)
    }
}

export const saveProviders = async function(data) {
	const base = `${process.env.REACT_APP_DORY_BASEURL}/insurance/v1/providers`;
    const newData = {
        "name": data
    }
	const config = {
		url: base,
		method: 'post',
		headers: {
			'Authorization': getToken(),
			'Content-Type': 'application/json'
		},
        data: newData
	}

    try {
        return await axios(config);
    } catch(error){
        const message = logMessage(error)
        return Promise.reject(message)
    }
}

export const userInfo = async function(url,method, data) {
    const base = url;
	const config = {
        url: base,
		method: method,
		headers: {
            'Authorization': getToken(),
			'Content-Type': 'application/json'
		},
        data: data
	}
    try {
        return await axios(config);
    } catch(error){
        const message = logMessage(error)
        console.log(message)
        return Promise.reject(message)
    }
}

export const submitDiscount = async function(url,method, data) {
	const base = url;
	const config = {
		url: base,
		method: method,
		headers: {
			'Authorization': getToken(),
			'Content-Type': 'application/json'
		},
        data: data
	}
    try {
        await axios(config);
        return json('Success')
    } catch(error){
        const message = logMessage(error)
        return Promise.reject(message)
    }
}

export const getTheme = async () => {
    const base = `${process.env.REACT_APP_DORY_BASEURL}/dory/partners/v1/${getPartnerId()}/theme`;
	const config = {
		url: base,
		method: `get`,
		headers: {
			'Authorization': getToken(),
			'Content-Type': 'application/json'
		}
	}
    try {
        const theme = await axios(config);
        saveTheme(theme.data);
        filterGen(theme.data.color)
    } catch(error){
        const message = logMessage(error)
        return Promise.reject(message)
    }
}

export const clickLogs = async (data) => {
	let url = `${process.env.REACT_APP_DORY_BASEURL}/dory/reports/v1/click/offers`;
	const config = {
		url: url,
		method: 'post',
		headers: {
			'Authorization': getToken(),
			'Content-Type': 'application/json'
		},
        data: data
	}
    try {
        await axios(config);
    } catch(error){
        const message = logMessage(error)
        return Promise.reject(message)
    }
}