import { userInfo } from "../api";
import { getUserId } from "./authViewModel";

export function saveEmail(data) {
    if(data) {
        localStorage.setItem('email', data);
    }
}

function saveUserId(data) {
    if(data) {
        localStorage.setItem('userId', data);
    }
}

export function userEmail () {
    const data = {
        "email" : localStorage.email || null
    }
    return data;
}

function getUserData() {
    const data = {
        "firstName" : localStorage.firstName || null ,
        "lastName" : localStorage.lastName || null,
        "email" : localStorage.email || null
    }
    return data;
}

export const createUser = async () => {
    const userId = getUserId()
    let url = "";
    let method = "";
    if (userId !== "null") {
        url = `${process.env.REACT_APP_DORY_BASEURL}/user/v1/${userId}`;
        method = "patch";
    }else{
        url = `${process.env.REACT_APP_DORY_BASEURL}/ingest/v1/user`;
        method = "post";
    }

    try{
        const userData = await userInfo(url, method, getUserData())
        saveUserId(userId !== "null" ? userId: userData.data.id)
    }catch(err){
        console.log(err)
        return Promise.reject(err)
    }
}