import React from 'react';
import './discountContainer.scss';
import { clickData } from 'utils/tools';
import { TrackGoogleAnalyticsEvent, TrackGoogleAnalyticsCustomEvent } from 'utils/google-analytics';

const DiscountContainer = (props) => {
    const data = props.data;
    const currentDiscount = props.currentDiscount;
    const containerStyles = {
        height: 66,
        width: '100%'
    }

    let maxDiscount = 35;

    const estimatedDiscount = maxDiscount - data.estimatedDiscount;
    const estimatedCurrentDiscount = maxDiscount - currentDiscount;
    
    const fillerStyles = {
        height: '100%',
        width: `${ 100 - estimatedDiscount}%`,
        borderRadius: 'inherit',
        textAlign: 'right'
    }
    const fillerStylesTwo = {
        height: '100%',
        width: `${100 - estimatedCurrentDiscount}%`,
        borderRadius: 'inherit',
        textAlign: 'right'
    }

    const buttonClick = (type, provider) => {
        clickData(type)
        TrackGoogleAnalyticsEvent("category:buttonClick",`action: ${type}.buttonClick`,`${provider}`)
        TrackGoogleAnalyticsCustomEvent({provider: provider})
    }
  return (
    <section>
        { data.bkg && <div className='discount-banner'>Recommended!</div>}
        <div className={`discountProfile ${data.bkg? data.bkg : ""}`}>
            <div className='name'><img src={data.insurerLogo} alt={data.insurerName}/> <span>{data.insurerName}</span></div>
            <div className='insurerDiscount blk-10' style={containerStyles}>
                <div className="progress-bar" style={fillerStyles}><span>Discount with {data.insurerName}</span></div>
            </div>
            <div className='insurerCurrentDiscount blk-10' style={containerStyles}>
                <div className="progress-bar" style={fillerStylesTwo}><span>Current Discount</span></div>
            </div>
            <div className='userDiscount'></div>
            <div className='btnGroup'>
                {data.offerUrl
                    ? <a href={data.offerUrl}><button onClick={() => buttonClick('get_quote', data.insurerName)} className='quoteBtn styledBtn primary-btn'>Get a quote</button></a>
                    : <a href={`tel:`+ data.phoneNumber}><button onClick={() => buttonClick('get_quote', data.insurerName)} className='quoteBtn styledBtn primary-btn'>Get a quote</button></a>
                }
                <a className='callBtn' href={`tel:`+ data.phoneNumber}><button onClick={() => buttonClick('get_quote', data.insurerName)}>Call an Agent</button></a>
            </div>
        </div>
    </section>
  )
}

export default DiscountContainer