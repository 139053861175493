import { userInfo } from "../api";
import { getItemAsJson, getItemAsBool} from 'utils/storage';

const saveDiscounts = (data, name) => {
    if(data) {
        localStorage.setItem(name, JSON.stringify(data));
    }
}

export const getDiscounts = (name) => {
    if (name){
        if(localStorage.getItem(name)){
            const devices = name === 'upgrades' ? JSON.parse(localStorage.getItem(name)).upgrades : JSON.parse(localStorage.getItem(name)).devices;
            return Object.keys(devices);
        }else{
            return [];
        }
    }
}

export const getPromonitoring = (name) => {
    let promonitoring = localStorage.getItem(name) || null;
    if (promonitoring){
        promonitoring = JSON.parse(promonitoring)
      return promonitoring
    }else{
        return "";
    }
}

export const chooseDiscounts = (data) => {

    let url = "";

    let discounts = {
        "devices": {},
        "upgrades": {}
    }

    if (data.upgrades){
        data.upgrades.map((t) => {
            switch (t) {
                case 'fortified':
                    Object.assign(discounts.upgrades, {"fortified": true})
                    break;
                case 'storm':
                    Object.assign(discounts.upgrades, {"storm": true})
                    break;
                case 'roof':
                    Object.assign(discounts.upgrades, {"roof": true})
                    break;
                case 'electrical':
                    Object.assign(discounts.upgrades, {"electrical": true})
                    break;
                case 'plumbing':
                    Object.assign(discounts.upgrades, {"plumbing": true})
                    break;
                case 'heating':
                    Object.assign(discounts.upgrades, {"heating": true})
                    break;
                default:
            }
            return discounts;
        })

        discounts.canProvidePhotoCertificate = data.canProvidePhotoCertificate;

        saveDiscounts(discounts, "upgrades");
        url = '/008';
    }
    
    if (data.securitySafety){
        data.securitySafety.map((t) => {
            switch (t) {
                case 'deadbolt':
                    Object.assign(discounts.devices, {"deadbolt": "1"})
                    break;
                case 'isGated':
                    Object.assign(discounts, {"isGated": true})
                    break;
                case 'theft':
                    Object.assign(discounts.devices, {"theft": "1"})
                    break;
                default:
            }
            return discounts;
        })
        
        saveDiscounts(discounts, "securitySafety");
        if (discounts.devices.theft){
            url = '/011';
        }else{
            url = '/012';
        }
    }

    if (data.fireSafety){
        data.fireSafety.map((t) => {
            switch (t) {
                case 'sprinkler':
                    Object.assign(discounts.devices, {"sprinkler": "1"})
                    break;
                case 'fireExtinguisher':
                    Object.assign(discounts.devices, {"fireExtinguisher": "1"})
                    break;
                case 'fireSmokeCo':
                    Object.assign(discounts.devices, {"fireSmokeCo": "1"})
                    break;
                default:
            }
            return discounts;
        })
        saveDiscounts(discounts, "fireSafety");
        if (discounts.devices.fireSmokeCo){
            url = '/013';
        }else{
            url = '/014';
        }
    }

    if (data.waterSafety){
        data.waterSafety.map((t) => {
            switch (t) {
                case 'leakDetection':
                    Object.assign(discounts.devices, {"leakDetection": "1"})
                    break;
                case 'waterShutoff':
                    Object.assign(discounts.devices, {"waterShutoff": "1"})
                    break;
                case 'powerGenerator':
                    Object.assign(discounts.devices, {"powerGenerator": "1"})
                    break;
                default:
            }
            return discounts;
        })
        saveDiscounts(discounts, "waterSafety");
        if (discounts.devices.leakDetection){
            url = '/015';
        }else if (discounts.devices.waterShutoff) {
            url = '/016';   
        } else{
            //url = '/017'
            if (localStorage.partnerId === "1004") {
                url = "/abodeConfirm";
            } else {
                url = "/confirm";
            }
        }
    }

    if (discounts.devices.length <= 0){
        delete discounts.devices;
    }else if (discounts.upgrades <= 0) {
        delete discounts.upgrades;
    }

    let securitySafety = getItemAsJson("securitySafety") || {}
    let fireSafety = getItemAsJson("fireSafety") || {}
    let waterSafety = getItemAsJson("waterSafety") || {}
    let upgrades = getItemAsJson("upgrades")?.upgrades || {}

    let discountFields = {
        "isGated": securitySafety?.isGated || false,
        "devices": {
            "deadbolt": securitySafety?.devices?.deadbolt || "0",
            "theft": securitySafety?.devices?.theft || "0",
            "sprinkler": fireSafety?.devices?.sprinkler || "0",
            "fireExtinguisher": fireSafety?.devices?.fireExtinguisher || "0",
            "fireSmokeCo": fireSafety?.devices?.fireSmokeCo || "0",
            "leakDetection": waterSafety?.devices?.leakDetection || "0",
            "waterShutoff": waterSafety?.devices?.waterShutoff || "0",
            "powerGenerator": waterSafety?.devices?.powerGenerator || "0"
        },
        "upgrades": {
            "fortified": upgrades?.fortified || false,
            "storm": upgrades?.storm || false,
            "roof": upgrades?.roof || false,
            "electrical": upgrades?.electrical || false,
            "plumbing": upgrades?.plumbing || false,
            "heating": upgrades?.heating || false,
        }
    }

    updateProWhenDeviceNotSelected(securitySafety, waterSafety, fireSafety);
    updateUser(discountFields);
    return url;        
}

export const proMonitoring = (data) => {
    const checkWaterSaftey = localStorage.getItem("waterSafety") ? JSON.parse(localStorage.getItem("waterSafety")).devices.waterShutoff : localStorage.getItem("waterSafety");
    let waterShutoff =  '';

    if (checkWaterSaftey instanceof SyntaxError){
        
    }else {
        waterShutoff = checkWaterSaftey ? true : false;
    }

    let url = "";

    if(data.proMonitoringTheftEnabled){
        data.proMonitoringTheftEnabled = data.proMonitoringTheftEnabled === "false" ? false : true;
        localStorage.setItem('proMonitoringTheftEnabled', data.proMonitoringTheftEnabled);
        url = '/012';
    }

    if(data.proMonitoringFireEnabled){
        data.proMonitoringFireEnabled = data.proMonitoringFireEnabled === "false" ? false : true;
        localStorage.setItem('proMonitoringFireEnabled', data.proMonitoringFireEnabled);
        url = '/014';
    }

    if(data.proMonitoringLeakEnabled){
        data.proMonitoringLeakEnabled = data.proMonitoringLeakEnabled === "false" ? false : true;
        localStorage.setItem('proMonitoringLeakEnabled', data.proMonitoringLeakEnabled);
        if (waterShutoff){
            url = "/016";
        }else{
            if (localStorage.partnerId === "1004") {
                url = "/abodeConfirm";
            } else {
                url = "/confirm";
            }
        }
    }

    if(data.proMonitoringWaterShutoffEnabled){
        data.proMonitoringWaterShutoffEnabled = data.proMonitoringWaterShutoffEnabled === "false" ? false : true;
        localStorage.setItem('proMonitoringWaterShutoffEnabled', data.proMonitoringWaterShutoffEnabled);
        if (localStorage.partnerId === "1004") {
            url = "/abodeConfirm";
        } else {
            url = "/confirm";
        }
    }
    
    let proMonitor = {
        "proMonitoringTheftEnabled": getItemAsBool("proMonitoringTheftEnabled"),
        "proMonitoringFireEnabled": getItemAsBool("proMonitoringFireEnabled"),
        "proMonitoringLeakEnabled": getItemAsBool("proMonitoringLeakEnabled"),
        "proMonitoringWaterShutoffEnabled": getItemAsBool("proMonitoringWaterShutoffEnabled")
    }

    updateUser(proMonitor)
    return url;
}

function getUserId() {
    return localStorage.userId || null;
}

const updateUser = async (data) =>{
    const url = `${process.env.REACT_APP_DORY_BASEURL}/user/v1/${getUserId()}`;
    const method = "patch";
    
    try{
        await userInfo(url, method, data)
    }catch(err){
        console.log(err)
        return Promise.reject(err)
    }
}

function updateProWhenDeviceNotSelected(securitySafety, waterSafety, fireSafety) {
    if (securitySafety?.devices?.theft !== "1") {
        localStorage.setItem('proMonitoringTheftEnabled', null);
    }

    if (waterSafety?.devices?.waterShutoff !== "1") {
        localStorage.setItem('proMonitoringWaterShutoffEnabled', null);
    }

    if (waterSafety?.devices?.leakDetection !== "1") {
        localStorage.setItem('proMonitoringLeakEnabled', null);
    }

    if (securitySafety?.devices?.theft !== "1") {
        localStorage.setItem('proMonitoringTheftEnabled', null);
    }

    if (fireSafety?.devices?.fireSmokeCo !== "1") {
        localStorage.setItem('proMonitoringFireEnabled', null);
    }

    // proMonitoring
    let proMonitor = {
        "proMonitoringTheftEnabled": getItemAsBool("proMonitoringTheftEnabled"),
        "proMonitoringFireEnabled": getItemAsBool("proMonitoringFireEnabled"),
        "proMonitoringLeakEnabled": getItemAsBool("proMonitoringLeakEnabled"),
        "proMonitoringWaterShutoffEnabled": getItemAsBool("proMonitoringWaterShutoffEnabled")
    }

    updateUser(proMonitor)
    
}