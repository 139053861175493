import { createSlice } from "@reduxjs/toolkit";
import { getUserData } from "view-models/nameViewModel";

const initialState = {
    name: getUserData().lastName ? true : false
};

export const navSlice = createSlice ({
    name: 'boolean',
    initialState,
    reducers: {
        nameExist: (state, action) => {
            state.name = action.payload
        }
    }
})

export const { nameExist } = navSlice.actions;

export default navSlice.reducer;