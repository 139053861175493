import options from './constants/securityOptions.json';
import upgrades from './constants/upgrades.json';
import { clickLogs } from 'api';
import { getThemeData } from './storage';
var pjson = require('../../package.json');


export const findOptions = (option) => {
    let list = [];
    if (option === "upgrades"){
       list = upgrades.data.find((value) => value.label === option);
    }else{
        list = options.securityOptions.find((value) => value.label === option);
    }
    return list;
}

const version = () => {
    return pjson.version;
}

export const Logout = () => {
    
    if(localStorage.partnerId === "1004"){
        localStorage.clear();
        window.location.href = `https://goabode.com`;
    }else{
        localStorage.clear();
        window.location.href = `${process.env.REACT_APP_AUTH_URL}/logout`;
    }
}

export const Redirect = () => {
    if(localStorage.partnerId === "1004"){
        const siteData = getThemeData()
        if (siteData.sessionResumeUrl){
            window.location.href = `${siteData.sessionResumeUrl}/${localStorage.userId}/resume`;
        }else{
            window.location.href = 'https://meetdory.com';
        }
    }else{
        localStorage.clear();
        window.location.href = 'https://meetdory.com';
    }
}

export const isEmpty = (object) => {
    let item;
    if (object === null || object === undefined || object === "undefined" || object === "null") {
        item = '';
    }else{
        item = object;
    }
    return item;
}

function osfunction ()
{
    let os = navigator.userAgent;
    let finalOs="";
    if (os.search('Windows')!==-1){
        finalOs="Windows";
    }
    else if (os.search('Mac')!==-1){
        finalOs="MacOS";
    }
    else if (os.search('X11')!==-1 && !(os.search('Linux')!==-1)){
        finalOs="UNIX";
    }
    else if (os.search('Linux')!==-1 && os.search('X11')!==-1){
        finalOs="Linux"
    }
    
    return finalOs;
}


export const clickData = async (button_clicked) => {
    const appVersion = version();
    const OSName = osfunction();
    const data = {
        "appVersion": appVersion,
        "button_clicked": `${button_clicked}`,
        "insurerName": localStorage.insurerName,
        "insurerId": localStorage.insuranceId,
        "operatingSystem":OSName,
        "operatingSystemVersion": navigator.userAgent,
        "source": "dory_web",
        "state": JSON.parse(localStorage.address).state,
        "userId": localStorage.userId
    }
    clickLogs(data)
}