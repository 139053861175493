export function saveName(fname, lname) {
    if(fname && lname) {
        localStorage.setItem('firstName', fname);
        localStorage.setItem('lastName', lname);
    }
}

export function getUserData() {
    const data = {
        "firstName": localStorage.firstName,
        "lastName": localStorage.lastName
    }
    return data;
}