import React, {useEffect} from 'react'
import CustomTheme from 'components/Shared/CustomTheme'
import './error.scss'
import { Redirect } from 'utils/tools'

const Authentication = () => {

    useEffect(() => {
        setTimeout(function () {
            Redirect()
         }, 4000);
    
    }, [])
    
    return (
        <section className='timeout'>
            <div className='content'>
                <CustomTheme />
                <div>Your session has timed out. Please log back in.</div>
                <button className='styledBtn primary-btn' onClick={() => Redirect()}>Back To Site</button>
            </div>
        </section>
    )
}

export default Authentication