import { userInfo } from "../api";

export function saveHomeType(data) {
    if(data) {
        localStorage.setItem('typeOfHome', data);
    }
}

function getUserId() {
    return localStorage.userId || null;
}

export function getHomeType(){
    return localStorage.typeOfHome || null;
}

export const updateUser = async(type) => {
    const typeHouse = {"typeOfHome": type}
    const url = `${process.env.REACT_APP_DORY_BASEURL}/user/v1/${getUserId()}`;
    const method = "patch";
    
    try{
        await userInfo(url, method, typeHouse)
        saveHomeType(typeHouse.typeOfHome)
    }catch(err){
        console.log(err)
        return Promise.reject(err)
    }
}