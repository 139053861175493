import { useEffect } from "react";
import ReactGA from "react-ga";

const InitializeGoogleAnalyticswPageView = (props) => {
    ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);
    useEffect(() => {
        TrackGoogleAnalyticsPageView(props.location)
    });
    
}

const TrackGoogleAnalyticsEvent = (
    category,
    action
  ) => {
    // Send UA Event
    ReactGA.event({
      category: category,
      action: action
    });
};

const TrackGoogleAnalyticsPageView = (pageView) => {
    // Send UA PageView
    ReactGA.set({ page: pageView });
    ReactGA.pageview(pageView);
}

const TrackGoogleAnalyticsCustomEvent = (customEvent) => {
  // Send UA CustomEvent
  ReactGA.set(customEvent);
}


export default InitializeGoogleAnalyticswPageView;
export {InitializeGoogleAnalyticswPageView, TrackGoogleAnalyticsEvent, TrackGoogleAnalyticsCustomEvent}