import React, {useState} from 'react'
import './abodeconfirm.scss'
import { useFormik } from 'formik';
import * as submission from '../../../view-models/discountSubmissionViewModel'
import { useNavigate } from "react-router-dom";
import { Icon } from '@iconify/react';
//import { getThemeData } from 'utils/storage';
import { logMessage } from 'utils/errorHandler';
import { TrackGoogleAnalyticsCustomEvent, TrackGoogleAnalyticsEvent } from 'utils/google-analytics';
import ReactGA from "react-ga";
import { isEmpty } from 'utils/tools';

const Confirmation = () => {
  const currentEstimatedDiscount = submission.getEstimatedDiscount();
  let navigate = useNavigate();
  const [discountAmount, setDiscount] = useState('');
  const [PolicyAmount, setPolicyAmount] = useState(isEmpty(localStorage.policyAmount)? isEmpty(localStorage.policyAmount): '');
  const [isClearable, setIsClearable] = useState(true);
  const [apiErrors, setApiErrors] = useState();
  const [errors, setErrors] = useState();
  const [dateType, setDateType] = useState('year');
  const [monthPrice, setMonthPrice] = useState(discountAmount.estimatedSavingsPerYear ? discountAmount.estimatedSavingsPerYear : 0);

  const insurerId = submission.getInsuranceId();

  
  //const primaryColor = getThemeData() ? getThemeData().color : '#4a3aff' ;


  const handleSubmit = async (e) => {
    e.preventDefault();
    try{
      if (isClearable){
        setErrors("")
        formik.values = Object.assign({"canProvidePhotoCertificate": true, "policyAmount": PolicyAmount}, formik.values)
        
        if (!discountAmount.estimatedDiscount){
          const discount = {
            "insurerId": insurerId,
            ...(PolicyAmount) && {policyAmount: PolicyAmount}
          };
          console.log(discount)
          const amount = await submission.getDiscountAmount(discount)
          setPolicyAmount(discount.policyAmount)
          setDiscount(amount.data)
        }
        await submission.updateUser(formik.values)
        submission.savePolicyAmount(PolicyAmount)
        submission.saveEstimatedDiscount(discountAmount.estimatedDiscount)
        TrackGoogleAnalyticsEvent("category:formSubmit","action: caculateDiscount")
        TrackGoogleAnalyticsCustomEvent("{canProvidePhotoCertificate: true}")
        navigate('/discounts')
      }else{
        setErrors( 'Please accept this statement to continue')
      }
    }catch(err){
      const message = logMessage(err)
      setApiErrors(message)
    }
  }

  const handleBlur = async () => {
    const discount = {
      "insurerId": insurerId,
      ...(PolicyAmount) && {policyAmount: PolicyAmount}
    };
    console.log(discount)
    const amount = await submission.getDiscountAmount(discount)
    setPolicyAmount(discount.policyAmount)
    setDiscount(amount.data)
  }

  const dateSwitch = (e) => {
    const type = e.target.value;
    switch (type) {
      case 'yr':
        setDateType('year');
        setMonthPrice(discountAmount.estimatedSavingsPerYear)
        break;
      case 'mo':
        setDateType('month');
        setMonthPrice(submission.monthCaculator(discountAmount.estimatedSavingsPerYear))
        break;
      default:
        break;
    }

  }

  const handleKeyUp = async (e) => {
    formik.handleChange(e)
    const discount = {
      "insurerId": insurerId,
      ...(e.target.value) && {policyAmount: e.target.value}
    };
    const amount = await submission.getDiscountAmount(discount)
    setPolicyAmount(discount.policyAmount)
    setDiscount(amount.data)
    switch (dateType) {
      case 'year':
        setMonthPrice(amount.data.estimatedSavingsPerYear)
        break;
      case 'month':
        setMonthPrice(submission.monthCaculator(amount.data.estimatedSavingsPerYear))
        break;
      default:
        break;
    }
  }

  let formik = useFormik({
    initialValues: {
      policyAmount: PolicyAmount? PolicyAmount : '',
    },
    onSubmit: () => {
        handleSubmit();
    }
  });

 const initData = async ()=> {
    if (isEmpty(localStorage.policyAmount) === '') return;

    try {
      const discount = {
        "insurerId": insurerId,
        "policyAmount": localStorage.policyAmount
      };
      const amount = await submission.getDiscountAmount(discount);
      setDiscount(amount.data);
      setPolicyAmount(discount.policyAmount);
      setMonthPrice(amount.data.estimatedSavingsPerYear)
     
    } catch (e) {}
  }

  window.onload = (() => {
    initData()
  })


  return (
    <section className='asavings'>
      <div className='asaving-container'>
          <div className='aconfirmInfo'>
              <div className='savingsText'>You can save up to</div>
              <div className='estimate'>
                <div className='value'>{isEmpty(discountAmount.estimatedDiscount)? isEmpty(discountAmount.estimatedDiscount) : isEmpty(currentEstimatedDiscount)}%</div>
                <div className='label'>per year</div>
              </div>              

              <div className='calculator'>
                <div className="perText"><span>If your premium yearly total is</span></div>
                <div className='calculator-container flex'>
                  <div className="dollar-div">
                    <input type="text" name="policyAmount" className="form-control dollar" id="floatingInput" placeholder="Enter (approx.) Policy Rate" onBlur={handleBlur} onChange={handleKeyUp} value={formik.values.policyAmount}/>
                    <div className='acol-one'>
                        <input id="cal-toggle-on" className="calToggle calToggle-left" name="calToggle" onClick={dateSwitch} value="Caculate" type="button" defaultChecked="true"/>
                        <label htmlFor="cal-toggle-on" className="cal-toggle-btn">Caculate</label>
                        <div className="cal_icon">
                           <Icon icon="mdi:calculator" color="white" width="20" height="30"/>
                        </div>
                    </div>
                  </div>
                </div>


                <div className='policyRate'>
                    <div>
                      <div className='saver'>
                        You could save up to 
                        <div className='amount'>
                          <div className='value'>${monthPrice ? monthPrice : ""}</div>
                          <div className='label'>per year</div>
                        </div>
                      </div>
                      <div className='atoggle'>
                        <input id="toggle-on" className="atoggle toggle-left" name="toggle" onClick={dateSwitch} value="yr" type="radio" defaultChecked="true"/>
                        <label htmlFor="toggle-on" className="toggle-btn">Year</label>
                        <input id="toggle-off" className="atoggle toggle-right" name="toggle" onClick={dateSwitch} value="mo" type="radio" />
                        <label htmlFor="toggle-off" className="toggle-btn">Month</label>
                      </div>
                      <div className='savingDisclaimer'>Some insurance companies use factored, rather than flat discounts, your savings may not be a direct percentage of your policy rate.</div>
                    </div> 
                  </div>
                  
              </div>
              
              <form className='adesktopBtn' onSubmit={handleSubmit}>
                {(apiErrors && !isClearable) && <span className='input-error'>{apiErrors}</span>}
                <button className='styledBtn primary-btn' type="submit">Claim My Discount</button>
              </form>
          </div>
      </div>

      <div className="claim-bottom">
        <div className='bottomDisclaimer'>
            <div className='aphotoBtn'>
                <label htmlFor="photo"> 
                  <p className='photoDisclaimer'>I certify accuracy, will provide proof if requested and authorize Dory to communicate with my insurer/agent for discount requests. Discount is subject to change. <ReactGA.OutboundLink eventLabel="developers" to="https://meetdory.com/legal"target="_blank">See Terms of Use.</ReactGA.OutboundLink></p>
                  <span className='checkbox'>
                      <input type="checkbox" id='photo' checked={isClearable} onChange={() => setIsClearable((state) => !state)} name="canProvidePhotoCertificate" value="true" />
                      <span className='checkmark'></span>
                  </span>
                </label>
            </div>
            {(errors && !isClearable) && <span className='input-error'>{errors}</span>}
        </div>
        <form className='amobileBtn' onSubmit={handleSubmit}>
          {(apiErrors && !isClearable) && <span className='input-error'>{apiErrors}</span>}
          <button className='astyledBtn primary-btn' type="submit">Claim My Discount</button>
        </form>
      </div>
    </section>
  )
}

export default Confirmation