import React, {useState} from 'react';
import { Container, Navbar, Offcanvas } from 'react-bootstrap';
import Logo from '../../../assets/images/logo.svg';
import './navbar.scss';
import { getUserData } from 'view-models/nameViewModel';
import { useSelector } from 'react-redux';
import { TrackGoogleAnalyticsEvent } from 'utils/google-analytics';

//Theme
import { getThemeData } from 'utils/storage';
import { SignOut } from '../PopUp';

import { Logout } from 'utils/tools';

const NavBar = () => {
  const {logo} = getThemeData()
  const { firstName, lastName } = getUserData();
  const [buttonPopup, setButtonPopup] = useState(false);
  const nameCheck = useSelector((state) => state.boolean.name);

  const buttonClick = () => {
    TrackGoogleAnalyticsEvent("category:buttonClick","action: logoClick")
  }

  return ( 
    <>
      {['lg'].map((expand) => (
        <Navbar key={expand} as="nav" expand={expand} className='top-nav'>
          <Container>
            <Navbar.Brand href="#">
              <img onClick={() => buttonClick()} src={logo? logo : Logo} alt="" />
            </Navbar.Brand>
            <div>
              <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
              <Navbar.Offcanvas id={`offcanvasNavbar-expand-${expand}`} aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`} placement="end">
                <Offcanvas.Header closeButton></Offcanvas.Header>
                <Offcanvas.Body className='desktop'>
                  {nameCheck && 
                    <div className='navbar-brand' onClick={() => setButtonPopup(!buttonPopup)}>
                      <span className='userInfo'>{firstName} {lastName}</span>
                      <SignOut trigger={buttonPopup} setTrigger={setButtonPopup}/>
                    </div>
                  }
                  {!nameCheck &&
                    <div className='navbar-brand' onClick={() => Logout()}>
                      <span className='userInfo'>Log Out</span>
                    </div>
                  }
                </Offcanvas.Body>
                <Offcanvas.Body className='mobile'>
                    <div className='mobile-nav'>
                      {firstName && 
                        <div>{firstName} {lastName}</div>
                      }
                      <div onClick={() => Logout()}>Log Out</div>
                    </div>
                </Offcanvas.Body>

              </Navbar.Offcanvas>
            </div>
          </Container>
        </Navbar>
      ))}
    </>
  );
}

export default NavBar