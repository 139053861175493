import { userInfo } from "api";

const getUserId = () => {
    return localStorage.userId || null;
}

export const getIsHomeOwner = () => {
    if(localStorage.isHomeOwner){
        return JSON.parse(localStorage.isHomeOwner) || null;
    }
}

const setIsHomeOwner = (data) => {
    if (data){
        localStorage.setItem('isHomeOwner', JSON.stringify(data))
    }
}

export const isHomeOwner = async (data) => {
    setIsHomeOwner(data.isHomeOwner)
    data.isHomeOwner = data.isHomeOwner === "false" ? false : true;
    const url = `${process.env.REACT_APP_DORY_BASEURL}/user/v1/${getUserId()}`;
    const method = "patch";
    try{
        await userInfo(url, method, data)
    } catch (err) {
        return err;
    }
}