import { userInfo } from "api";

export const saveToken = (token) => {
    if(token) {
        localStorage.setItem('authToken', token);
    }
}

const caculateDiscount = (data) => {
    localStorage.setItem('currentInsurerDiscount', data);
}

export const getToken = () => {
    return localStorage.authToken || null;
}

export const savePartnerId = (id) => {
    if(id) {
        localStorage.setItem('partnerId', id);
    }
}

export const getPartnerId = () => {
    return localStorage.partnerId || null;
}

export const saveUserId = (id) => {
    if(id) {
        localStorage.setItem('userId', id);
    }
}

const saveDiscounts = (data, name) => {
    if(data) {
        localStorage.setItem(name, JSON.stringify(data));
    }
}

export const getUserId = () => {
    return localStorage.userId || null;
}

function saveUserData(data) {
    let discountTotal = 0;
    if (data) {
        if (data.didSubmitDiscount){
            window.location.href = '/compare';
        }
        localStorage.setItem('firstName', data.firstName || "")
        localStorage.setItem('lastName', data.lastName || "")
        localStorage.setItem('email', data.email || "")
        localStorage.setItem('typeOfHome', data.typeOfHome || null)
        localStorage.setItem('address', data.address ? JSON.stringify(data.address): "")
        localStorage.setItem('proMonitoringEnabled', data.proMonitoringEnabled|| null)
        localStorage.setItem('proMonitoringTheftEnabled', data.proMonitoringTheftEnabled|| null)
        localStorage.setItem('proMonitoringWaterShutoffEnabled', data.proMonitoringWaterShutoffEnabled|| null)
        localStorage.setItem('proMonitoringLeakEnabled', data.proMonitoringLeakEnabled|| null)
        localStorage.setItem('proMonitoringFireEnabled', data.proMonitoringFireEnabled|| null)
        localStorage.setItem('policyAmount', data.policyAmount|| "")
        localStorage.setItem('insurerName', data.insurerName|| "")
        localStorage.setItem('isHomeOwner', data.isHomeOwner|| null)
        localStorage.setItem('insuranceId', data.insurerId|| "")
        localStorage.setItem('didSubmitDiscount', data.didSubmitDiscount|| "")
        if (data.policyNumber || data.agentEmail || data.policyExpirationMonth || data.policyNumber){
            const policyInfo = {
                ...(data.agentEmail) && {agentEmail: data.agentEmail},
                ...(data.agentPhone) && {agentPhone: data.agentPhone},
                ...(data.policyExpirationMonth) && {policyExpirationMonth: data.policyExpirationMonth},
                ...(data.policyNumber) && {policyNumber: data.policyNumber}
            }
            localStorage.setItem('agentInfo', JSON.stringify(policyInfo));
        }

        //Save discounts
        
        if (data.devices) {
            let waterSafety = {
                "devices": {}
            }
            
            if(data.devices.leakDetection){ Object.assign(waterSafety.devices, {"leakDetection": "1"}) 
            discountTotal += 1}
            if(data.devices.waterShutoff){ Object.assign(waterSafety.devices, {"waterShutoff": "1"})
            discountTotal += 2}
            if(data.devices.powerGenerator){ Object.assign(waterSafety.devices, {"powerGenerator": "1"})
            discountTotal += 2}
            saveDiscounts(waterSafety, 'waterSafety')
        }

        if (data.devices) {
            let fireSafety = {
                "devices": {}
            }
            
            if(data.devices.fireExtinguisher){ Object.assign(fireSafety.devices, {"fireExtinguisher": "1"})
            discountTotal += 0.5}
            if(data.devices.fireSmokeCo){ Object.assign(fireSafety.devices, {"fireSmokeCo": "1"})
            discountTotal += 1}
            if(data.devices.sprinkler){ Object.assign(fireSafety.devices, {"sprinkler": "1"})
            discountTotal += 1}
            saveDiscounts(fireSafety, 'fireSafety')
        }

        if (data.devices || data.isGated) {
            let securitySafety = {
                "devices": {}
            }
            if(data.devices.deadbolt){ Object.assign(securitySafety.devices, {"deadbolt": "1"})
            discountTotal += 1}
            if(data.isGated){ Object.assign(securitySafety, {"isGated": true})
            discountTotal += 0.5}
            if(data.devices.theft){ Object.assign(securitySafety.devices, {"theft": "1"})
            discountTotal += 1}
            saveDiscounts(securitySafety, 'securitySafety')
        }

        if (data.upgrades){
            let item = {
                "upgrades": {}
            }
            if(data.upgrades.fortified) {Object.assign(item.upgrades, {"fortified": true})
            discountTotal += 0.5}
            if(data.upgrades.storm) {Object.assign(item.upgrades, {"storm": true})
            discountTotal += 0.5}
            if(data.upgrades.roof) {Object.assign(item.upgrades, {"roof": true})
            discountTotal += 5}
            if(data.upgrades.electrical) {Object.assign(item.upgrades, {"electrical": true})
            discountTotal += 0.5}
            if(data.upgrades.plumbing) {Object.assign(item.upgrades, {"plumbing": true})
            discountTotal += 1}
            if(data.upgrades.heating) {Object.assign(item.upgrades, {"heating": true})
            discountTotal += 0.5}

            item.canProvidePhotoCertificate = data.canProvidePhotoCertificate;

            saveDiscounts(item, "upgrades");
        }

        if (data.retired || data.isSpecialOccupation || data.isSmokingFree || data.loyalty || data.claimFree || data.mortgageFreeAndLienFree) {
            let item = {}
            if(data.retired) {Object.assign(item, {"retired": true})
            discountTotal += 3}
            if(data.isSpecialOccupation) {Object.assign(item, {"isSpecialOccupation": true})
            discountTotal += 3}
            if(data.isSmokingFree) {Object.assign(item, {"isSmokingFree": true})
            discountTotal += 1}
            if(data.loyalty) {Object.assign(item, {"loyalty": true})
            discountTotal += 1}
            if(data.claimFree) {Object.assign(item, {"claimFree": true})
            discountTotal += 1}
            if(data.mortgageFreeAndLienFree) {Object.assign(item, {"mortgageFreeAndLienFree": true})
            discountTotal += 1}

            saveDiscounts(item, "extraDiscounts");
        }
        caculateDiscount(discountTotal)
    }
}

export const getSubmitDiscount = () => {
    return localStorage.didSubmitDiscount || null;
}

export const getCurrentData = () => {
    return JSON.parse(localStorage.getItem('userData')) || null;
}

export const checkUser = async (userId) => {
    const url = `${process.env.REACT_APP_DORY_BASEURL}/user/v1/${userId}/details`;
    const method = "get";
    try{
        const userData = await userInfo(url, method)
        saveUserData(userData.data)
        return {"didSubmit" : userData.data.didSubmitDiscount};
    }catch(err){
        console.log(err)
        return Promise.reject(err)
    }
}