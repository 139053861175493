
export const logMessage = (err) => {
    let message = '';
    let status = err.status ? err.status : 400

    if (err.response){
        if(err.response.data.errorCode === 3007){
            window.location.href = '/compare'
            return;
        }
        status = err.response.status
    }

    switch (status) {
        case 401:
            window.location.href = '/unauthorized'
            break;
        case 2005:
            message = "User's address is not covered"
            break;
        default:
            message = 'There was an issue, Please try again. ';
            break;
    }

    return message;
}