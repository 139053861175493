import { submitDiscount, userInfo } from "../api";

function getUserId() {
    return localStorage.userId || null;
}

export function getInsuranceName(){
    return localStorage.insurerName || null;
}

export const getDiscount = () =>  {
    return localStorage.offers ? JSON.parse(localStorage.offers) : '';
}

export function getInsuranceId(){
    return localStorage.insuranceId || null;
}

export function saveClaim(data){
    localStorage.setItem('claimFree', data);
}

function saveAgentInfo(data){
    localStorage.setItem('agentInfo', JSON.stringify(data));
}

export const agentInfo = async (data, date) => {

    const policyInfo = {
        ...(data.agentEmail) && {agentEmail: data.agentEmail},
        ...(data.agentPhone) && {agentPhone: data.agentPhone},
        ...(date) && {policyExpirationMonth: date},
        ...(data.policyNumber) && {policyNumber: data.policyNumber}
    }

    const quoteInfo = {
        "userId" : getUserId()
    }

    const url = `${process.env.REACT_APP_DORY_BASEURL}/user/v1/${getUserId()}`;
    const quoteUrl = `${process.env.REACT_APP_DORY_BASEURL}/quote/v1`;
    const method = "patch";
    const quoteMethod = "post";
    try{
        await userInfo(url, method, policyInfo)
        userInfo(quoteUrl, quoteMethod, quoteInfo)
        saveAgentInfo(policyInfo)
    }catch(err){
        console.log(err)
        return Promise.reject(err)
    }
}

export const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

export const updateUser = async (data) => {

    const url = `${process.env.REACT_APP_DORY_BASEURL}/user/v1/${getUserId()}`;
    const method = "patch";
    try{
        await userInfo(url, method, data)
    }catch(err){
        console.log(err)
        return Promise.reject(err)
    }
}

export const submit = async () =>{
    const url = `${process.env.REACT_APP_DORY_BASEURL}/user/v1/${getUserId()}/discountSubmission`;
    const method = "post";
    
    try{
        await submitDiscount(url, method)
        //await getOffers();
    }catch(err){
        console.log(err)
        return Promise.reject(err)
    }
}

export const getDiscountAmount = async (data) =>{
    const url = `${process.env.REACT_APP_DORY_BASEURL}/user/v1/${getUserId()}/discount`;
    const method = "post";
    
    try{
        return await userInfo(url, method, data)
    }catch(err){
        console.log(err)
        return Promise.reject(err)
    }
}

function saveOffersDetails(data){
    localStorage.setItem('offers', JSON.stringify(data));
}

export const roundTotal = (number) => {
    return Math.round(number);
}

export const saveEstimatedDiscount = (data) => {
    localStorage.setItem('currentInsurerDiscount', data)
}

export const savePolicyAmount = (data) => {
    localStorage.setItem('policyAmount', data)
}

export const getEstimatedDiscount = () => {
    return localStorage.currentInsurerDiscount ? localStorage.currentInsurerDiscount : 0;
}

export const getOffers = async () =>{
    const url = `${process.env.REACT_APP_DORY_BASEURL}/user/v1/${getUserId()}/discounts/compare`;
    const method = "get";
    
    try{
        const offers = await userInfo(url, method)
        saveOffersDetails(offers.data)
    }catch(err){
        console.log(err)
        return Promise.reject(err)
    }
}

export const monthCaculator = (price) => {
    if (price){
        const newPrice = Number(price) / 12;
        return Math.round(newPrice * 100) / 100;
    }
}