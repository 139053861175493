import React from 'react'
import { Container } from 'react-bootstrap';
import './footer.scss'
import ReactGA from "react-ga";
import { getThemeData } from 'utils/storage';

const Footer = () => {
    const d = new Date();
    let year = d.getFullYear();
    const supportEmail = getThemeData()? getThemeData().supportEmail: "support@meetdory.com";
  return (
        <footer>
            <Container>
                <div className='links'>
                    <ReactGA.OutboundLink eventLabel="privacypolicy" to="https://meetdory.com/legal"target="_blank">Privacy Policy</ReactGA.OutboundLink>
                    <ReactGA.OutboundLink eventLabel="termsOfUse" to="https://meetdory.com/legal"target="_blank">Terms Of Use</ReactGA.OutboundLink>
                    <ReactGA.OutboundLink eventLabel="referralTermsOfUse" to="https://meetdory.com/legal"target="_blank">Referral Terms Of Use</ReactGA.OutboundLink>
                    <ReactGA.OutboundLink eventLabel="partners" to="https://partners.meetdory.com"target="_blank">Partners</ReactGA.OutboundLink>
                    <ReactGA.OutboundLink eventLabel="developers" to="https://docs.meetdory.com"target="_blank">Developers</ReactGA.OutboundLink>
                </div>
                <div className='copyright'>Copyright {year}</div>
                <div className='copyright'>{supportEmail}</div>
            </Container>
        </footer>
  )
}

export default Footer