import React from 'react';
import './error.scss';

export const PageNotFound = () => {
	return (
		<div>
			<div className='error'>
				<h1>Page Not Found</h1>
				<p>The page you are looking for doesn't exist or has been moved.</p>
			</div>
		</div>
	);
};

export default PageNotFound;
