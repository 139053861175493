import React, { useState, useEffect} from 'react'
import './discounts.scss'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import hamburger from '../../../assets/images/collapseHamburger.svg';
import { ReactComponent as Reminder } from '../../../assets/images/remind.svg';
import { useNavigate, useLocation } from "react-router-dom";
import * as submission from '../../../view-models/discountSubmissionViewModel';
import DatePicker from "react-multi-date-picker";
import { Icon } from '@iconify/react';
import Loader from 'components/Shared/Loader';
import { getUserData } from 'view-models/nameViewModel';
import { SpeechBubble } from 'components/Shared/PopUp';
import { logMessage } from 'utils/errorHandler';
import { getThemeData } from 'utils/storage';
import { isEmpty } from 'utils/tools';

export const Discounts = (props) => {
    const saveDirect = localStorage.getItem('saveDirectSubmission');
    let navigate = useNavigate();
    const location = useLocation();
    const formStatus = props.formStatus ? props.formStatus : true;
    let [value, setExpireMonth] = useState(null);
    const [showForm] = useState(formStatus);
    const currentInsurerDiscount = submission.getEstimatedDiscount();
    const [isLoading, setIsLoading] = useState(false);
    const [buttonPopup, setButtonPopup] = useState(false);
    const [errors, setErrors] = useState('');

    const claimLater = () => {
        submission.saveClaim(true)
        navigate('/legal')
    }

    const handleFetch = async (data) => {
        try {
            setIsLoading(true)
            const month = value ? value.month.name : "";
            if (location.pathname === "/compare"){
                await submission.agentInfo(data, month);
                props.setTrigger(true);
                setIsLoading(false)
            }else {
                await submission.agentInfo(data, month);
                navigate('/legal')
            }
        }catch(err){
            setIsLoading(false)
            const message = logMessage(err)
            setErrors(message)
        }
    }

    function CustomMultipleInput({openCalendar, value, handleValueChange}) {
        return (
            <div className='form-floating'>
                <input
                    onFocus={openCalendar}
                    placeholder="Policy Renewal Month (optional)"
                    className="form-control"
                    id='floatingInput'
                    type="text"
                    value={value}
                    name="policyExpirationMonth"
                    onChange={handleValueChange}
                />
                <label for="floatingInput">Policy Renewal Month (optional)</label>
            </div>
        )
    }

    const formik = useFormik({
        initialValues: {
            policyNumber: '',
            agentEmail: '',
        },
        validationSchema: Yup.object({
            policyNumber: Yup.string().trim().required('Please Enter Your Policy Number'),
            agentEmail: saveDirect === 'false' ? Yup.string().email('Not a valid email').required("Please Enter Agent's Email") : Yup.string().email('Not a valid email')
        }),
        onSubmit: () => {
            handleFetch(formik.values)
        }
    });

    return (
        <section className={location.pathname === "/discounts" ? `single` : ``}>
            { showForm && 
                <div className='discount'>
                    <div className='loaderContainer'> 
                        {isLoading ? <Loader /> : ""}
                    </div>
                        <div className='discountContainer'>
                            <div className='title'>Get up to {isEmpty(currentInsurerDiscount)}% off your current policy by completing your discount request</div>
                            <div className='content'>Enter the following information and we'll take it from here!</div>
                                <form onSubmit={formik.handleSubmit}>
                                    <fieldset className={ (saveDirect === 'false') ? ``: `saveDirect`}>
                                        <div className="form-floating mb-3">
                                            <input name='policyNumber' type='text' onChange={formik.handleChange} className={ formik.errors.policyNumber ? `border-error form-control`: `form-control`} id="floatingInput" placeholder="Home Insurance Policy Number" />
                                            <label for="floatingInput">Home Insurance Policy Number</label>
                                            {formik.errors.policyNumber && <span className='input-error'>{formik.errors.policyNumber}</span>}
                                        </div>
                                        <div className=" mb-3">
                                            <DatePicker value={value} onChange={setExpireMonth} months={submission.months} onlyMonthPicker format="MMMM" render={<CustomMultipleInput />}/>
                                        </div>
                                    </fieldset>
                                    {saveDirect === 'false' && 
                                        <fieldset>
                                            <div className="form-floating mb-3">
                                                <input name="agentEmail" type='text' className={ formik.errors.agentEmail ? `border-error form-control`: `form-control`} onChange={formik.handleChange} id="floatingInput" placeholder="Agent's Email" />
                                                <label for="floatingInput">Agent's Email</label>
                                                {formik.errors.agentEmail && <span className='input-error'>{formik.errors.agentEmail}</span>}
                                            </div>
                                            <div className="form-floating mb-3">
                                                <input name="agentPhone" type='text' className={ formik.errors.agentPhone ? `border-error form-control`: `form-control`} onChange={formik.handleChange} id="floatingInput" placeholder="Agent's Phone Number (optional)" />
                                                <label for="floatingInput">Agent's Phone Number</label>
                                            </div>
                                        </fieldset>
                                    }
                                    
                                    <div className='discountDisclaimer'>
                                        <SpeechBubble trigger={buttonPopup} setTrigger={setButtonPopup}>We'll remind you to recalculate your discount before renewal, show you competitive offers, and help you resubmit your discount for the new plan year.</SpeechBubble>
                                        <div type="button" className="btn-secondary" onClick={() => setButtonPopup(true)}>
                                            Why do we want this?
                                        </div>
                                    </div>
                                    <div className='block-space'>
                                        {errors && <span className='input-error'>{errors}</span>}
                                        <button className='styledBtn primary-btn' type="submit" >Next</button>
                                    </div>
                                </form>
 
                                {(location.pathname === "/compare" || location.pathname === "/legal") ? (
                                    <>
                                        <button className='showHide'>
                                            <img src={hamburger} onClick={() => props.setButton(false)} alt='' />
                                        </button> 
                                    </>
                                ) : (
                                    <>
                                        {(saveDirect !== "false") && <button className='claimBtn' onClick={claimLater}> Maybe later</button> }
                                    </>
                                )}
                        </div>
                </div>
            }
        </section>
    )
}

export const CompleteDiscount = (props) => {
    const { firstName } = getUserData();
    const [mainColor, setThemeColor] = useState('')

    useEffect(() => {
        setThemeColor(getThemeData() ? getThemeData().color : '#4a3aff');
    }, [])

    return (props.setButton) ? (
        <section className='completed'> 
            <div> 
                <Reminder className="reminder-btn" fill={mainColor}/>
                <div className='two-row'><span>Hey {firstName}, don't forget to submit your discount request</span><button onClick={() => props.setButton(true)}> Complete My Request</button></div>
                
            </div>
        </section>
    ) : "";
}

export const ClaimedDiscounts = (props) => {
    useEffect(() => {
        if (props.showClaimed){
            setTimeout(() => {
                props.setComplete(false)
            }, 3000);
        }
    }, [props])
    
    return (props.setTrigger) ? (
        <div className='claimedContainer'>
        <button className="close-btn" onClick={() => [props.setTrigger(false), props.setComplete(false)]}><Icon icon="material-symbols:close" color="#000" width="16" height="16" inline={true} /></button>
        <div>
            <Icon icon="material-symbols:check-circle-rounded" color="#2AA331" width="39" height="39" inline={true} />
            <span className='title'>Your discount has been submitted!</span>
        </div>
    </div>
    ) : "";
}

export default Discounts;