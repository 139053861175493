import { Textbox, Email, TypeOfHome, InsurerList, CheckboxButtons, Boolean, DiscountList, Rent } from '../DiscountForm/FormComponents';
import { Address } from '../DiscountForm/FormComponents/Address';

/*export function FormElement(props) {
  switch(props.item) {
    case '1':
      return <Textbox type="double" url={props.path} />;
    case '2':
      return <Email name="email" placeholder="Email" url={props.path} />;
    case '3':
      return <Address name="address" url={props.path} />;
    case '4':
      return <TypeOfHome optionType="typeOfHome" url={props.path} />;
    case '5':
      return <Rent url={props.path} />;
    case '6':
      return <InsurerList url={props.path} />;
    case '10':
      return <CheckboxButtons optionType="securitySafety" url={props.path} />;
    case '11':
      return <Boolean nameType="proMonitoringTheftEnabled" url={props.path} />;
    case '12':
      return <CheckboxButtons optionType="fireSafety" url={props.path} />;
    case '13':
      return <Boolean nameType="proMonitoringFireEnabled" url={props.path} />;
    case '14':
      return <CheckboxButtons optionType="waterSafety" url={props.path} />;
    case '15':
      return <Boolean nameType="proMonitoringLeakEnabled" url={props.path} />;
    case '16':
      return <Boolean nameType="proMonitoringWaterShutoffEnabled" url={props.path} />;
    case '17':
      return <CheckboxButtons optionType="upgrades" url={props.path} />;
    case '18':
      return <DiscountList url={props.path} />
    default:
  }
}*/


export function FormElement(props) {
  switch(props.item) {
    case '1':
      return <Textbox type="double" url={props.path} />;
    case '2':
      return <Email name="email" placeholder="Email" url={props.path} />;
    case '3':
      return <Address name="address" url={props.path} />;
    case '4':
      return <TypeOfHome optionType="typeOfHome" url={props.path} />;
    case '5':
      return <Rent url={props.path} />;
    case '6':
      return <InsurerList url={props.path} />;
    case '7':
      return <CheckboxButtons optionType="upgrades" url={props.path} />;
    case '8':
      return <DiscountList url={props.path} />
    
    case '10':
      return <CheckboxButtons optionType="securitySafety" url={props.path} />;
    case '11':
      return <Boolean nameType="proMonitoringTheftEnabled" url={props.path} />;
    case '12':
      return <CheckboxButtons optionType="fireSafety" url={props.path} />;
    case '13':
      return <Boolean nameType="proMonitoringFireEnabled" url={props.path} />;
    case '14':
      return <CheckboxButtons optionType="waterSafety" url={props.path} />;
    case '15':
      return <Boolean nameType="proMonitoringLeakEnabled" url={props.path} />;
    case '16':
      return <Boolean nameType="proMonitoringWaterShutoffEnabled" url={props.path} />;
    default:
  }
}