import React from 'react';

const Layout = ({ children }) => {
  return (
    <div id="root-layout" className="h-100 d-flex flex-column">
        <main className="h-100 flex-fill flex-column d-flex">
            {children}
        </main>
    </div>
  )
}

export default Layout