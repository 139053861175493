import React, { useState, useEffect } from 'react';
import './legal.scss';
import switchImg from '../../../assets/images/switchlegal.png';
import mobile from '../../../assets/images/mobiletool.svg';
import { useNavigate } from "react-router-dom";
import * as submission from '../../../view-models/discountSubmissionViewModel';
import * as compare from '../../../view-models/compareDiscountsViewModel';
import Loader from 'components/Shared/Loader';
import * as discounts from 'components/DiscountForm/SubmitDiscount';

const Legal = () => {
    let navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [buttonClose, setCloseButton] = useState(false);
    const [showComplete, setCompleteSection] = useState(compare.getAgentInfo()? true : false);

    const handleFetch = async (e) => {
        e.preventDefault();
        try {
            setIsLoading(true)
            //await submission.submit();
            await submission.getOffers();
            navigate('/compare')
        }catch(err){
            setIsLoading(false)
        }
    }

    useEffect(() => {
        submission.submit();
    }, [])

  return (
    <section className='legalSection'>
        {(!compare.getAgentInfo() && !buttonClose) && <discounts.CompleteDiscount button={buttonClose} setButton={setCloseButton}/> }
        {(buttonClose || showComplete) && 
            <div className='claimDiscount'>
            {showComplete ? <discounts.ClaimedDiscounts trigger={buttonClose} showClaimed={showComplete} setComplete={setCompleteSection} setTrigger={setCloseButton} /> : <discounts.Discounts formStatus="false" button={buttonClose} setButton={setCloseButton} trigger={showComplete} setTrigger={setCompleteSection}/> }
            </div>
        }
        <div className='discount legal'>
            <div className='discountContainer'>
                <div className='title'>Customers Save Even More With Our Discount Comparison Tool</div>
                <div className='content'>We match the discounts you qualify for to providers rewarding them the most.</div>
                <form onSubmit={handleFetch}>
                    <img className='desktopImg' src={switchImg} alt="" />
                    <img className='mobileImg' src={mobile} alt="" />
                    <button className='styledBtn primary-btn' type="submit" >Compare My Discount</button>
                </form>
                <div className='legalDisclaimer'>
                    In clicking you allow us to save & share your information with insurance partners for discounts & quotes. We may be compensated by such parties. Please <a href="https://meetdory.com/legal" target="_blank" rel="noreferrer">click here</a> to see important terms and limitations on insurance partner offers. Insurer terms & conditions apply.
                </div>
            </div>
            <div className='loaderContainer'> 
                {isLoading ? <Loader /> : ""}
            </div>
        </div>
    </section>
  )
}

export default Legal