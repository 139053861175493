import './App.scss';
import { Route, Routes, Navigate } from 'react-router-dom';
import PrivateRoutes from 'components/routing/PrivateRoutes';
import { Helmet } from 'react-helmet';
import InitializeGoogleAnalyticswPageView from 'utils/google-analytics';

// Path
import { START, LOGIN } from './components/routing/index';
import { PageNotFound } from './components/routing/error';
import Authentication from 'components/routing/error/Authentication';

// Store
import { Provider } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit'
import amountReducer from './Redux/amountSlice';
import nameReducer from './Redux/navSlice';

//Routes
import Create from './views/create';
import Login from 'views/login';

const store = configureStore({
  reducer: {
    counter: amountReducer,
    boolean: nameReducer,
  }
});

const App = () => {
  ;
  return (
    <Provider store={store}>
        <Helmet>
          <title>Dory Web App</title>
        </Helmet>
        <InitializeGoogleAnalyticswPageView location={window.location.pathname + window.location.search}/>
        <Routes>
          <Route path={LOGIN} element={<Login/>} />
          <Route path='/unauthorized' element={<Authentication/>} />
          <Route element={<PrivateRoutes/>}>
            <Route path={START} element={<Create />} />
            <Route path="*" element={<Navigate to="/001" replace />}/>
          </Route>
          <Route path="/*" element={<PageNotFound/>} />
        </Routes>
    </Provider>

  );
}

export default App;
