import React, {useState, useEffect} from 'react';
import {findOptions} from '../../../utils/tools';
import insureList from '../../../utils/constants/insuranceCompanies.json'
import './formcomponents.scss';
import { Icon } from '@iconify/react';
import { ReactComponent as Renters } from '../../../assets/images/rent1.svg';
import { ReactComponent as Owner } from '../../../assets/images/own1.svg';
import { ReactComponent as Roadblock } from '../../../assets/images/roadblock1.svg';
import { ReactComponent as House } from '../../../assets/images/house.svg';
import { ReactComponent as Condo } from '../../../assets/images/townhouse.svg';
import { ReactComponent as Apartment } from '../../../assets/images/apartment.svg';
import { ReactComponent as Mobile } from '../../../assets/images/mobile.svg';
import { useNavigate } from "react-router-dom";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { getInsurerList} from '../../../utils/storage';
import * as type from '../../../view-models/homeTypeViewModel'
import * as userInfo from '../../../view-models/nameViewModel'
import * as userEmail from '../../../view-models/emailCreateViewModel'
import * as insurance from '../../../view-models/insuranceViewModel'
import * as discounts from '../../../view-models/discountsViewModel'
import * as extraDiscounts from '../../../view-models/upgradesViewModel'
import * as submission from '../../../view-models/discountSubmissionViewModel'
import Popup from '../../Shared/PopUp'
import CreatableSelect from 'react-select/creatable';
import { useDispatch } from 'react-redux';
import { incrementByAmount } from "Redux/amountSlice"
import { nameExist } from "Redux/navSlice"
import { getThemeData } from '../../../utils/storage';
import { logMessage } from 'utils/errorHandler';
import Loader from 'components/Shared/Loader';
import { Redirect } from '../../../utils/tools';
import { isHomeOwner, getIsHomeOwner } from 'view-models/homeOwnerViewModel';
import { TrackGoogleAnalyticsEvent, TrackGoogleAnalyticsCustomEvent } from 'utils/google-analytics';

export const Textbox = (props) => {
    let navigate = useNavigate();
    const dispatch = useDispatch();

    const { firstName, lastName} = userInfo.getUserData();
    const formik = useFormik({
        initialValues: {
          firstName: firstName? firstName : '',
          lastName: lastName? lastName : ''
        },
        validationSchema: Yup.object({
          firstName: Yup.string()
            .required('Please enter A first name'),
          lastName: Yup.string()
            .required('Please enter a last name')
        }),
        onSubmit: () => {
            <Loader />
            if (formik.values.firstName && formik.values.firstName){
                userInfo.saveName(formik.values.firstName, formik.values.lastName)
                dispatch(nameExist(true))
            }
            TrackGoogleAnalyticsEvent("category:formSubmit","action: name")
            TrackGoogleAnalyticsCustomEvent(`{name: ${formik.values.firstName} ${formik.values.lastName}}`)
            navigate('/002')
        }
      });
    return (
        <div className='textData'>
            <form onSubmit={formik.handleSubmit}>
                    <div><div className="form-floating mb-3">
                        <input name='firstName' type='text' className={ formik.errors.firstName ? 'border-error form-control person-icon': "form-control person-icon"} id="floatingInput" onChange={formik.handleChange} value={formik.values.firstName}  placeholder="First Name" />
                        <label for="floatingInput">First Name</label>
                        {(formik.errors.firstName) && <span className='input-error'>{formik.errors.firstName}</span> }
                    </div>
                    <div className="form-floating mb-3">
                        <input name="lastName" type='text' className={ formik.errors.lastName ? 'border-error form-control person-icon': "form-control person-icon"} id="floatingInput" onChange={formik.handleChange} value={formik.values.lastName} placeholder="Last Name" />
                        <label for="floatingInput">Last Name</label>
                        {(formik.errors.lastName) && <span className='input-error'>{formik.errors.lastName}</span> }
                    </div> </div>
                    
                    
                <button className='styledBtn primary-btn block-space' type="submit" >Next</button>
            </form>
        </div>
    )
}

export const Email = (props) => {
    const {name, placeholder} = props;
    const {email} = userEmail.userEmail();
    let navigate = useNavigate();
    const [errors, setErrors] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const handleSubmit = async () => {
        try {
            setIsLoading(true)
            await userEmail.saveEmail(formik.values.email)
            await userEmail.createUser()
            TrackGoogleAnalyticsEvent("category:formSubmit","action: email")
            TrackGoogleAnalyticsCustomEvent(`{email: ${formik.values.email}}`)
            navigate('/003')
            
        } catch (error) {
            setIsLoading(false)
            const message = logMessage(error)
            setErrors(message)
        }
    }
    const formik = useFormik({
        initialValues: {
          email: email? email: '',
        },
        validationSchema: Yup.object({
          email: Yup.string().email('Not a valid email format').required('Please enter a email'),
        }),
        onSubmit: () => {
            handleSubmit()
        }
    });
    return (
      <div className='textData'>
        <form onSubmit={formik.handleSubmit}>
          <div className="form-floating mb-3">
              <input type="text" name={name} className={ formik.errors.email ? `border-error form-control ${name}-icon`: `form-control ${name}-icon`} id="floatingInput" placeholder={placeholder} onChange={formik.handleChange} value={formik.values.email} />
              <label for="floatingInput">{placeholder}</label>
              {(formik.errors.email) && <span className='input-error'>{formik.errors.email}</span> }
          </div>
          <div className='block-space'>
            {(errors) && <span className='input-error'>{errors}</span> }
            <button className='styledBtn primary-btn' type="submit">Next</button>
          </div>
        </form>
        {isLoading ? <Loader /> : ""}
      </div>
    )
}

export const TypeOfHome = (props) => {
    let navigate = useNavigate();
    const homeType = type.getHomeType();
    const [selected, setSelected] = useState(homeType? homeType: '');
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState('');
    const [mainColor, setThemeColor] = useState('')

    useEffect(() => {
        setThemeColor(getThemeData() ? getThemeData().color : '#4a3aff');
    }, [])
    

    const submitHandle = async () => {
        try {
            setIsLoading(true)
            await type.updateUser(selected)
            TrackGoogleAnalyticsEvent("category:formSubmit","action: typeOfHome")
            TrackGoogleAnalyticsCustomEvent(`{typeOfHome: ${selected}}`)
            navigate('/005')
        } catch (error) {
            setIsLoading(false)
            const message = logMessage(error)
            setErrors(message)
            Promise.reject(message)
        }
    }
    const formik = useFormik({
        initialValues: {
            typeOfHome: homeType ? homeType : '',
        },
        validationSchema: Yup.object({
            typeOfHome: Yup.string()
            .label('Type of home')
        }),
        onSubmit: () => {
            submitHandle()
        }
    });
    return (
      <div className='buttonContainer'>
        <form onSubmit={formik.handleSubmit}>
            <div className='row twoCol'>
                <div className='buttonIcons col'>
                    <label>
                        <input type="radio" checked={selected === 'house'} name="typeOfHome" onChange={() => setSelected('house')} value={selected} />
                        <div className='tiles vector'>
                            <House stroke={mainColor} />
                            <span>Single Family House</span>
                        </div>
                    </label>
                </div>
                <div className='buttonIcons col'>
                    <label>
                        <input type="radio" checked={selected === 'condo'} name="typeOfHome" onChange={() => setSelected('condo')} value={selected} />
                        <div className='tiles vector'>
                            <Condo stroke={mainColor} />
                            <span>Condo</span>
                        </div>
                    </label>
                </div>
                <div className='buttonIcons col'>
                    <label>
                        <input type="radio" checked={selected === 'apartment'} name="typeOfHome" onChange={() => setSelected('apartment')} value={selected} />
                        <div className='tiles vector'>
                            <Apartment stroke={mainColor} />
                            <span>Apartment</span>
                        </div>
                    </label>
                </div>
                <div className='buttonIcons col'>
                    <label>
                        <input type="radio" checked={selected === 'mobile'} name="typeOfHome" onChange={() => setSelected('mobile')} value={selected} />
                        <div className='tiles vector'>
                            <Mobile stroke={mainColor} />
                            <span>Mobile</span>
                        </div>
                    </label>
                </div>
            </div>
            {formik.errors.typeOfHome && <span className='input-error'>{formik.errors.typeOfHome}</span>}
            <div className='block-space'>
                {(errors) && <span className='input-error'>{errors}</span> }
                <button className='styledBtn primary-btn' type="submit">Next</button>
          </div>
        </form>
        {isLoading ? <Loader /> : ""}
      </div>
    )
}


export const InsurerList = (props) => {
    let navigate = useNavigate();
    const [valueOptions, setValue] = useState(insurance.getInsurance() ? insurance.getInsurance() : "");
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState('');

    let list = getInsurerList() ? getInsurerList().sort((a, b) => a.name.localeCompare(b.name)) : "";
    
    if (!list){
        list = insureList.insurerCompanies;
    }

    const newList = list.map(
        ({ name,id, isDirectSubmission, ...rest}) => ({label: name, value: JSON.stringify({name,id,isDirectSubmission}), ...rest})
    )
    
    const handleSubmit = async() => {
        try {
            setIsLoading(true)
            if(valueOptions.__isNew__){
                let newRecord = await insurance.addInsurance(valueOptions.value);
                newRecord.isDirectSubmission = false;
                newRecord.isDefault = true;
                TrackGoogleAnalyticsCustomEvent(`{insurance: ${newRecord}}`)
                await insurance.updateUser(newRecord);
            }else{
                if(!valueOptions.value){
                    setErrors('Please an Insurance Provider')
                }
                const insuranceData = JSON.parse(valueOptions.value)
                TrackGoogleAnalyticsCustomEvent(`{insurance: ${insuranceData}}`)
                await insurance.updateUser(insuranceData)
            }
            TrackGoogleAnalyticsEvent("category:formSubmit","action: insurance")
            
            navigate('/007')
        }catch(error){
            setIsLoading(false)
            const message = logMessage(error)
            setErrors(message)
            Promise.reject(message)
        }
    }


    const formik = useFormik({
        initialValues: {
        },
        validationSchema: Yup.object({
            insuranceName: Yup.string()
        }),
        onSubmit: () => {
            handleSubmit()
        }
    });
    return (
        <div className='textData'>
            <form onSubmit={formik.handleSubmit}>
                <CreatableSelect isClearable placeholder={'Select Provider'} className={ formik.errors.insuranceName ? `border-error provider`: `provider`} options={newList} onChange={setValue} value={valueOptions} name="insuranceName"/>
                <div className='block-space'>
                    {errors && <span className='input-error'>{errors}</span>}
                    <button className='styledBtn primary-btn' type="submit" >Next</button>
                </div>
            </form>
            
            {isLoading ? <Loader /> : ""}
        </div>
    )
}


export const CheckboxButtons = (props) => {
    const {optionType} = props;
    const options = optionType? optionType : "";
    const items = discounts.getDiscounts(optionType);
    let [isChecked, setIsChecked] = useState(items);
    const list = findOptions(options);
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [mainColor, setThemeColor] = useState('')

    useEffect(() => {
        setIsLoading(false)
        setThemeColor(getThemeData() ? getThemeData().color : '#4a3aff');
    }, [])

    const handleClick = e => {
        let temp = isChecked;
        if(e.target.checked===true){
            dispatch(incrementByAmount(Number(e.target.getAttribute('discount'))))
            temp.push(e.target.value);
            setIsChecked([...temp])
        }else{
            dispatch(incrementByAmount(-Number(e.target.getAttribute('discount'))))
            setIsChecked((current) =>
                current.filter((item) => item !== e.target.value)
            );
        }
    }

    const handleSubmit = async () => {
        try {
            setIsLoading(true)
            let newArray = {};
            newArray[options] = isChecked;
            const url = await discounts.chooseDiscounts(newArray)
            TrackGoogleAnalyticsEvent("category:formSubmit",`action: ${optionType}`)
            TrackGoogleAnalyticsCustomEvent(`{${optionType}: ${JSON.stringify(newArray[options])}}`)
            await whenToConfirm(url);
            setIsLoading(false)
            navigate(url)
        } catch (error) {
            setIsLoading(false)
            const message = logMessage(error)
            Promise.reject(message)
        }
    }

    let formik = useFormik({
        initialValues: {
        },
        onSubmit: () => {
            handleSubmit();
        }
    });
    
    return (
        <div className='buttonContainer'>
            <form onSubmit={formik.handleSubmit}>
                <div className={optionType ==="upgrades"? 'row threeCol' : 'row'}>
                    {list.items.map(info => (
                        <label key={info.id} className='buttonIcons col'>
                            <input type="checkbox" checked={isChecked.includes(info.value)} discount={info.discountAmount} onChange={handleClick} name={optionType} value={info.value} />
                            <div className="icon-box">
                                <Icon icon={info.icon} color={mainColor}/>
                                <span className='label'>{info.text}</span>
                            </div>
                        </label>
                    ))}
                </div>
                <div className='block-space'>
                    <button className='styledBtn primary-btn' type="submit">Next</button>
                </div>
            </form>
            {isLoading ? <Loader /> : ""}
        </div>
    )
}

export const Boolean = (props) => {
    const {nameType} = props;
    let navigate = useNavigate();
    const checkedItem = discounts.getPromonitoring(nameType);
    const [errors, setErrors] = useState();
    const [selected, setSelected] = useState(checkedItem);
    const [isLoading, setIsLoading] = useState(false);
    const [mainColor, setThemeColor] = useState('')

    useEffect(() => {
      if (selected === false){
        setSelected('false')
      }
      setThemeColor(getThemeData() ? getThemeData().color : '#4a3aff');
      setIsLoading(false)
    }, [selected])
    
    
    const handleSubmit = async () => {
        if (selected === null){
            setErrors('Please select one')
        }
        try {
            setIsLoading(true)
            let newObject = {};
            newObject[nameType] = selected;
            const url = await discounts.proMonitoring(newObject);
            TrackGoogleAnalyticsEvent("category:formSubmit",`action: ${nameType}`)
            TrackGoogleAnalyticsCustomEvent(`{${nameType}: ${JSON.stringify(newObject[nameType])}}`)
            await whenToConfirm(url);
            setIsLoading(false)
            navigate(url)
        } catch (error) {
            setIsLoading(false)
            const message = logMessage(error)
            setErrors(message)
        }
    }
    const formik = useFormik({
        initialValues: {
        },
        onSubmit: () => {
            handleSubmit();
        }
    });
    
    return (
        <div className='buttonContainer'>
            <form onSubmit={formik.handleSubmit}>
                <div className='row boolean'>
                    <div className='buttonIcons col'>
                        <label>
                            <input type="radio" name={nameType} checked={selected === true} onChange={() => setSelected(true)} value="true" />
                            <div className='confirmBtn'>
                                <Icon icon="iconoir:thumbs-up" color={mainColor} className='iconBtn' />
                                <span>Yes</span>
                            </div>
                        </label>
                    </div>
                    <div className='buttonIcons col'>
                        <label>
                            <input type="radio" name={nameType} checked={selected === "false"} onChange={() => setSelected("false")} value="false" />
                            <div className='confirmBtn'>
                                <Icon icon="iconoir:thumbs-down" color={mainColor} className='iconBtn' />
                                <span>No</span>
                            </div>
                        </label>
                    </div>
                </div>
                <div className='block-space'>
                    {errors&& <span className='input-error'>{errors}</span> }
                    <button className='styledBtn primary-btn' type="submit" >Next</button>
                </div>
            </form>
            {isLoading ? <Loader /> : ""}
        </div>
    )
}

export const DiscountList = () => {
    let navigate = useNavigate();
    const discounts = extraDiscounts.getDiscounts();
    const [selected, setSelected] = useState(discounts);
    const dispatch = useDispatch();
    //const insurerId = submission.getInsuranceId();
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async () => {
        try {
            setIsLoading(true)
            let newArray = {};
            //const discount = {
            //    "insurerId": insurerId
            //};
            newArray['extraDiscounts'] = selected;
            // 1. update discount fields
            const url = await extraDiscounts.saveDiscounts(newArray)
            // 2. calculate discount
            // TODO using local variables call "calculate" endpoint
            //const amount = await submission.getDiscountAmount(discount)
            //submission.saveEstimatedDiscount(amount.data.estimatedDiscount)
            TrackGoogleAnalyticsEvent("category:formSubmit",`action: discountList`)
            TrackGoogleAnalyticsCustomEvent(`{discountList: ${JSON.stringify(newArray['extraDiscounts'])}}`)
            navigate(url)
        } catch (error) {
            setIsLoading(false)
            const message = logMessage(error)
            Promise.reject(message)
        }
    }

    const handleClick = e => {
        let temp = selected;
        if(e.target.checked===true){
            dispatch(incrementByAmount(Number(e.target.getAttribute('discount'))))
            temp.push(e.target.value);
            setSelected([...temp])
        }else{
            dispatch(incrementByAmount(-Number(e.target.getAttribute('discount'))))
            setSelected((current) =>
                current.filter((item) => item !== e.target.value)
            );
        }
    }

    const formik = useFormik({
        initialValues: {
        },
        onSubmit: () => {
            handleSubmit()
        }
    });
    return(
        <div className='discountList' >
            <form onSubmit={formik.handleSubmit}>
                {extraDiscounts.discountsList.list.map(info => (
                    <label key={info.id} className='discountItems'>
                        <input type="checkbox" checked={selected.includes(info.formName)} discount={info.discountAmount} onChange={handleClick} name="extraDiscounts" value={info.formName} />
                        <span>{info.title}</span>
                    </label>
                ))}
                <div className='block-space'>
                    <button className='styledBtn primary-btn' type="submit">Next</button>
                </div>
            </form>
            {isLoading ? <Loader /> : ""}
        </div>
    )
}

export const Rent = (props) => {
    let navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [mainColor, setThemeColor] = useState('')
    const [errors, setErrors] = useState();
    const homeOwner = getIsHomeOwner();
    const [selected, setSelected] = useState(homeOwner);
    
    useEffect(() => {
        if (selected === "false"){
            setSelected('false')
        }
        setThemeColor(getThemeData() ? getThemeData().color : '#4a3aff');
    }, [selected])

    const handleSubmit = async() => {
        try {
            if (selected === null){
                setErrors('Home Owner is required')
                return;
            }
            isHomeOwner({"isHomeOwner": selected})
            if (selected === "false"){
                setButtonPopup(true)
            }else{
                setIsLoading(true)
                TrackGoogleAnalyticsEvent("category:formSubmit","action: homeOwner")
                TrackGoogleAnalyticsCustomEvent(`{homeOwner: ${selected}}`)
                navigate('/006')
            }
        } catch (err) {
            const message = logMessage(err);
            setErrors(message)
        }
    }

    const formik = useFormik({
        initialValues: {
        },
        onSubmit: () => {
            handleSubmit()
        }
    });
    const [buttonPopup, setButtonPopup] = useState(false);
    return (
        <div className='buttonContainer'>
            <Popup trigger={buttonPopup} setTrigger={setButtonPopup}>
                <div className="whyPopup">
                    <Roadblock fill={mainColor}/>
                    <div className='title'>Renters discount, coming soon! </div>
                    <div className='content'>We're working with providers to find all the best discounts for renters. Once we're ready to go, we'll let you know!</div>
                    <div className='block-space'>
                        <button className='styledBtn primary-btn' onClick={() => Redirect()}>Exit</button>
                    </div>
                </div>
            </Popup>
            <div className='background'></div>
            <form onSubmit={formik.handleSubmit}>
                <div className='row boolean'>
                    <div className='buttonIcons col'>
                        <label>
                            <input type="radio" name="isHomeOwner" checked={selected === "false"} onChange={() => setSelected("false")} value="false"/>
                            <div className='confirmBtn vector'>
                                <Renters fill={mainColor} />
                                <span>Rent</span>
                            </div>
                        </label>
                    </div>
                    <div className='buttonIcons col'>
                        <label>
                            <input type="radio" name="isHomeOwner" checked={selected === true} onChange={() => setSelected(true)} value="true"/>
                            <div className='confirmBtn vector'>
                                <Owner fill={mainColor}/>
                                <span>Own</span>
                            </div>
                        </label>
                    </div>
                </div>
                <div className='block-space'>
                    {errors&& <span className='input-error'>{errors}</span> }
                    <button className='styledBtn primary-btn' type="submit" >Next</button>
                </div>
            </form>
            {isLoading ? <Loader /> : ""}
        </div>
    )
}

const whenToConfirm = async (url) => {
    if (url === "/abodeConfirm" || url === "/confirm") {
        // TODO using local variables call "calculate" endpoint
        const amount = await submission.getDiscountAmount({"insurerId": submission.getInsuranceId()});
        submission.saveEstimatedDiscount(amount.data.estimatedDiscount)
    }
}