import React, {useState, useEffect} from 'react';
import './formcomponents.scss';
import { useNavigate } from "react-router-dom";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { updateUser, getUserAddress } from '../../../view-models/addressViewModel';
import Loader from 'components/Shared/Loader';
import { TrackGoogleAnalyticsEvent } from 'utils/google-analytics';
import { isEmpty } from 'utils/tools';

export const Address = (props) => {
    const suggestionElement = document.getElementById("suggestionBox");
    const currentAddress = getUserAddress();
    let [data] = useState({address: {address1: isEmpty(currentAddress.address)? isEmpty(currentAddress.address.address1) : '', address2:isEmpty(currentAddress.address)? isEmpty(currentAddress.address.address2) : '', city: isEmpty(currentAddress.address)? currentAddress.address.city : '', state:isEmpty(currentAddress.address)? isEmpty(currentAddress.address.state) : '', zip:isEmpty(currentAddress.address)? isEmpty(currentAddress.address.zip): "", country:"US"}})
    const [fullAddress, setAddress] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState('');
    const [disabled, setDisabled] = useState(isEmpty(currentAddress.address)? false: true);

    
    useEffect(() => {
        const suggestionElement = document.getElementById("suggestionBox");
        suggestionElement.classList.remove("active")
        suggestionElement.classList.add("inactive")
        if(currentAddress.fullAddress){
            setAddress(currentAddress.fullAddress)
        }


    }, [currentAddress, suggestionElement])

    let formik = useFormik({
        initialValues: {
          address1: currentAddress.fullAddress? currentAddress.fullAddress: fullAddress,
          address2: ''
        },
        validationSchema: Yup.object({
            address1: Yup.string()
            .required('Please enter a valid address')
        }),
        onSubmit: () => {
            handleSubmit()
        }
    });
    

    const handleKeyUp = (e) => {
        const searchValue = e.target.value;

        suggestionElement.innerHTML = "";
        if(!searchValue) {
            suggestionElement.classList.remove("active");
            suggestionElement.classList.add("inactive");
            return;
        }else{
            suggestionElement.classList.remove("inactive");
            suggestionElement.classList.add("active");
            suggestionElement.classList.add("spinner");
        }
        
        sendLookupRequest(searchValue)
    };

    const fetchAddress = async (item) => {
        const params = new URLSearchParams({
            key: process.env.REACT_APP_SMARTY_WEBSITE_ID,
            search: item,
            max_results: 10
        });
    
        const request = await fetch(
            `${process.env.REACT_APP_SMARTY_URL}?${params}`
        );
    
        return await request.json();
    }
    
    const sendLookupRequest = async (searchValue) => {

        const data = await fetchAddress(searchValue);
        
        if (data.suggestions.length > 0){
            formatSuggestions(data.suggestions)
        }else {
            suggestionElement.classList.remove("active");
            suggestionElement.classList.add("inactive");
            setErrors('No Matching Address')
        }
    }

    const checkValue = async (e) => {
        const results = await fetchAddress(e.target.value);
        if (results.suggestions.length === 0){
            setErrors('No Matching Address')
        }else{
            setErrors()
            setDisabled(false)
        }
    }

    const formatSuggestions = (suggestions) => {
        const lastTen = suggestions.slice(-10);
        const formattedSuggestions = lastTen.map((suggestion) => {
            const divElement = document.createElement("div");
            suggestionElement.classList.remove("spinner");
            
            divElement.innerText = `${suggestion.street_line} ${suggestion.city} ${suggestion.state} ${suggestion.zipcode}`;

            divElement.addEventListener("click", () => {
                suggestionElement.classList.remove("active");
                suggestionElement.classList.add("inactive");
                populateForm(suggestion)
            })

            return divElement;
        })
        suggestionElement.append(...formattedSuggestions);
    }

    const populateForm = (suggestion) => {
        document.getElementById("floatingInput address").value = `${suggestion.street_line} ${suggestion.city} ${suggestion.state} ${suggestion.zipcode}`;
        document.getElementsByClassName("apt").value = `${suggestion.secondary}`;

        data.address.address1= suggestion.street_line;
        data.address.city= suggestion.city;
        data.address.state= suggestion.state;
        data.address.zip= suggestion.zipcode;
        const newAddress = `${suggestion.street_line} ${suggestion.city} ${suggestion.state} ${suggestion.zipcode}`;
        setAddress(newAddress);
        formik.values.address1 = newAddress;
    }

    const {name} = props;
    let navigate = useNavigate();

    const handleSubmit = async () => {
        try {
            if (isEmpty(data.address.zip) === '') {
                setErrors('No zip found in address')
                return;
            }
            setIsLoading(true)
            await updateUser(data);
            TrackGoogleAnalyticsEvent("category:formSubmit","action: address")
            navigate('/004')
        } catch (error) {
            setIsLoading(false)
            console.log(error)
        }
    }

    return (
      <div className='addressData'>
        <form onSubmit={formik.handleSubmit}>
            <div className="addressFields">
                <div className="form-floating mb-3">
                    <input type="text" name='address1' className={ formik.errors.address1 ? `border-error form-control ${name}-icon address`: `form-control ${name}-icon`} id="floatingInput address" placeholder="Address" onBlur={checkValue} onKeyUp={handleKeyUp} onChange={formik.handleChange} value={formik.values.address1} />
                    <label for="floatingInput">Address</label>
                    <div id='suggestionBox'></div>
                    {(formik.errors.address1 || errors) && 
                        <div>
                            <span className='input-error'>{formik.errors.address1}</span>
                            {(errors) && 
                                <span className='input-error'>{errors}</span>
                            }
                        </div>
                    }
                </div>
                <div className="form-floating mb-3">
                    <input type="text" name='address2' className="form-control apt" id="floatingInput" placeholder="Apt #" />
                    <label for="floatingInput">Apt #</label>
                </div>
            </div>
            
            <div className='block-space'>
                <button className='styledBtn primary-btn' disabled={errors || formik.errors.address1 || disabled} type="submit">Next</button>
            </div>
        </form>
        {isLoading ? <Loader /> : ""}
      </div>
    )
}