import React from 'react'
import BoxContainer from '../Shared/BoxContainer';
import * as p from '../Shared/Progress';
import './formstart.scss';
import { FormElement } from '../SelectComponents';


const FormStart = (props) => {
  let {mainTitle, progress, subTitle, formData, path} = props.data;

  return (
    <section className='discountSection'>
      <p.ProgressBar completed={progress} />
      <BoxContainer mainTitle={mainTitle} subTitle={subTitle} progress={progress} formData={formData}> 
          <div className='top-section'>
          </div>
          <div className='boxContent'>
            <div className='formHeader'>
              <label className='mainTitle'>{mainTitle}</label>
              <label className='subTitle'>{subTitle}</label>
            </div>
            <div className='formData'>
              <FormElement item={formData} path={path} />
            </div>
          </div>
      </BoxContainer> 
    </section>
  )
}

export default FormStart