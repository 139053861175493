import { Outlet } from 'react-router-dom';
import { getToken } from 'view-models/authViewModel';
import Authentication from './error/Authentication';

const PrivateRoutes = () => {
  let auth = getToken();

  return (
    auth ? <Outlet/> : <Authentication/>
  )
}

export default PrivateRoutes