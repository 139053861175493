import React, {useState, useEffect} from "react";
import './progress.scss'
import { useSelector } from "react-redux";
import { SpeechBubble } from "../PopUp";


export const ProgressBar = (props) => {
  const { completed } = props;

  const containerStyles = {
    height: 10,
    width: '100%',
    background: '#F8F8F8'
  }

  const fillerStyles = {
    height: '100%',
    width: `${completed}%`,
    textAlign: 'right'
  }

  return (
    <div className="containerStyle" style={containerStyles}>
      <div className="progress" style={fillerStyles}>
      </div>
    </div>
  );
};


export const DiscountPercentage = (props) => {

  const count = useSelector((state) => state.counter.amount);

  let finalValue = 35;
  const [buttonPopup, setButtonPopup] = useState(false);

  if (buttonPopup === true){
    setTimeout(() => {
      setButtonPopup(false)
    }, 3000);
  }

  const between = (x, min, max) => {
    return x > min && x <= max;
  }

  useEffect(() => {
    if(between(count, 0, 1)) {
      localStorage["discountView"] = false;
      setButtonPopup(true)
    }
  }, [count])
  

  return (
    <section>
      <SpeechBubble trigger={buttonPopup} setTrigger={setButtonPopup}>Great start! Watch the discount meter increase with each selection! </SpeechBubble>
      <div className="">
        <div className="progressText">%</div>
        <progress value={count} max={finalValue}>
          <span className="progress-meter-text">%</span> 
        </progress>
      </div>
    </section>
  );
}
  
export default ProgressBar;