
export function saveTheme(data){
    if(data){
        localStorage.setItem('siteTheme', JSON.stringify(data));
    }
}

export function getThemeData(){
    return JSON.parse(localStorage.getItem('siteTheme')) || '';
}

export function saveInsurerList(list) {
    if(list) {
        localStorage.setItem('InsurerList', JSON.stringify(list));
    }
}

export function saveUserDetails(user) {
    if(user) {
        localStorage.setItem('user', JSON.stringify(user));
    }
}

export function getInsurerList() {
    return JSON.parse(localStorage.getItem('InsurerList')) || null;
}


export function saveSessionInfo(data) {
    if(data) {
        localStorage.setItem('sessionInfo', JSON.stringify(data));
    }
}

export function getItemAsJson(name) {
    try {
        let ret = JSON.parse(localStorage.getItem(name))
        return ret || {}
    } catch(e) {
        console.log(e)
        return {}
    }
}

export function getItemAsBool(name) {
    try {
        if(localStorage.getItem(name).toLowerCase() === "true"){
            return true
        } else if (localStorage.getItem(name).toLowerCase() === "false") {
            return false
        } else {
            return false
        }
    } catch(e) {
        console.log(e)
        return false
    }
}