import { userInfo, saveProviders } from "../api";

function saveInsurance(data) {
    if(data) {
        localStorage.setItem('insurerName', data.name);
        localStorage.setItem('insuranceId', data.id);
        localStorage.setItem('saveDirectSubmission', data.isDirectSubmission);
    }
}

export function getInsurance(){
    if (localStorage.insurerName){
        return {
            "label": localStorage.insurerName,
            "value": JSON.stringify({"name":localStorage.insurerName, "id":localStorage.insuranceId})
        }
    }else {
        return null
    }
}



function getUserId() {
    return localStorage.userId || null;
}

export const addInsurance = async(data) => {
    try{
        const item = await saveProviders(data)
        return item.data;
    }catch(err){
        console.log(err)
        return Promise.reject(err)
    }
}

export const updateUser = async(type) => {
    const data = {"insurerName": type.name, "insurerId": type.id};

    const url = `${process.env.REACT_APP_DORY_BASEURL}/user/v1/${getUserId()}`;
    const method = "patch";
    try{
        await userInfo(url, method, data)
        saveInsurance(type)
    }catch(err){
        console.log(err)
        return Promise.reject(err)
    }
}