import { createSlice } from "@reduxjs/toolkit";
import { getEstimatedDiscount } from "view-models/discountSubmissionViewModel";

const initialState = {
    amount: Number(getEstimatedDiscount()) ? Number(getEstimatedDiscount()) : 0
};

export const amountSlice = createSlice ({
    name: 'counter',
    initialState,
    reducers: {
        incrementByAmount: (state, action) => {
            state.amount += action.payload
        }
    }
})

export const { incrementByAmount } = amountSlice.actions;

export default amountSlice.reducer;