import { userInfo } from "../api";
import { getItemAsJson } from 'utils/storage';


const saveDiscountsStorage = (data, name) => {
    if(data) {
        localStorage.setItem(name, JSON.stringify(data));
    }
}

export const getDiscounts = () =>  {
    if(localStorage.extraDiscounts){
        const parseObject = JSON.parse(localStorage.extraDiscounts)
        return Object.keys(parseObject);
    }else{
        return [];
    }
}

export const discountsList = {
    "list": [
        {
            "id": 1,
            "title": "I am active military, a teacher or a first responder",
            "formName": "isSpecialOccupation",
            "discountAmount": 3
        },
        {
            "id": 2,
            "title": "I am retired or over the age of 55",
            "formName": "retired",
            "discountAmount": 3
        },
        {
            "id": 3,
            "title": "There are no smokers in my home",
            "formName": "isSmokingFree",
            "discountAmount": 1
        },
        {
            "id": 4,
            "title": "I've been with the same insurer for over 1-year",
            "formName": "loyalty",
            "discountAmount": 1
        },
        {
            "id": 5,
            "title": "I have not filed a claim in the past 3 years",
            "formName": "claimFree",
            "discountAmount": 1
        },
        {
            "id": 6,
            "title": "My home is mortgage/lien free",
            "formName": "mortgageFreeAndLienFree",
            "discountAmount": 1
        }
    ]
}

export const saveDiscounts = async (data) => {

    let discounts = {}

    let url = "";

    if (data.extraDiscounts){
        data.extraDiscounts.map((t) => {
            switch (t) {
                case 'isSpecialOccupation':
                    Object.assign(discounts, {"isSpecialOccupation": true})
                    break;
                case 'retired':
                    Object.assign(discounts, {"retired": true})
                    break;
                case 'isSmokingFree':
                    Object.assign(discounts, {"isSmokingFree": true})
                    break;
                case 'loyalty':
                    Object.assign(discounts, {"loyalty": true})
                    break;
                case 'claimFree':
                    Object.assign(discounts, {"claimFree": true})
                    break;
                case 'mortgageFreeAndLienFree':
                    Object.assign(discounts, {"mortgageFreeAndLienFree": true})
                    break;
                default:
            }
            return discounts;
        })
        saveDiscountsStorage(discounts, "extraDiscounts");

        /*if (localStorage.partnerId === "1004") {
            url = "/abodeConfirm";
        } else {
            url = "/confirm";
        }*/
        url = "/010";
       
    }

    let extraDiscounts = getItemAsJson("extraDiscounts")

    let discountFields = {
        "isSpecialOccupation": extraDiscounts?.isSpecialOccupation || false,
        "retired": extraDiscounts?.retired || false,
        "isSmokingFree": extraDiscounts?.isSmokingFree || false,
        "loyalty": extraDiscounts?.loyalty || false,
        "claimFree": extraDiscounts?.claimFree || false,
        "mortgageFreeAndLienFree": extraDiscounts?.mortgageFreeAndLienFree || false
    }

    // this is the last step to update discount fields, make sure all updated
    await updateUser(discountFields)
    return url;
}

function getUserId() {
    return localStorage.userId || null;
}

const updateUser = async (data) =>{
    const url = `${process.env.REACT_APP_DORY_BASEURL}/user/v1/${getUserId()}`;
    const method = "patch";
    
    try{
        await userInfo(url, method, data)
    }catch(err){
        console.log(err)
        return Promise.reject(err)
    }
}