import { userInfo } from "../api";

function getUserId() {
    return localStorage.userId || null;
}

export function getUserAddress() {
    let data = localStorage.address || null;
    if (data){
        const parseAddress = JSON.parse(data);
        data = {
            'fullAddress': `${parseAddress.address1} ${parseAddress.city}, ${parseAddress.state} ${parseAddress.zip}`,
            'address': parseAddress
        }

        return data;
    }else{
        return "";
    }
}

function saveUserAddress(address) {
    if(address) {
        localStorage.setItem('address', JSON.stringify(address));
    }
}

export const updateUser = async(address) => {
    const url = `${process.env.REACT_APP_DORY_BASEURL}/user/v1/${getUserId()}`;
    const method = "patch";
    
    try{
        await userInfo(url, method, address)
        saveUserAddress(address.address)
    }catch(err){
        console.log(err)
        return Promise.reject(err)
    }
}